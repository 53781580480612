import React from "react";
import { animated } from "react-spring";
import moment from "moment";
import { Button } from "reactstrap";

const PayrollBookingCard = ({
  row,
  animationProps,
  toggleShowTimeModal,
  setDocURL,
  lastElementRef,
  isSelected,
  onItemSelect,
  ClientLabel,
  checkBox,
}) => {
  return (
    <div ref={lastElementRef} key={row.id}>
      <animated.div style={animationProps} ref={lastElementRef}>
        <div className="card mb-2">
          <div className="card-body">
            <div className="flex flex-row d-flex justify-content-between">
              <div className="d-flex" style={{ width: "250px" }}>
                {checkBox && (
                  <div className="d-flex align-items-center mr-3">
                    <span className="mx-2">
                      <label className="ui-check m-0">
                        <input
                          type="checkbox"
                          name="id"
                          checked={isSelected(row.bookingRef)}
                          onChange={() => onItemSelect(row)}
                        />
                        <i></i>
                      </label>
                    </span>
                  </div>
                )}

                <div>
                  <div>{row.booking.candidateName}</div>
                  <div className="item-mail text-muted h-1x d-none d-sm-block">
                    <small>
                      Booking Date:{" "}
                      {moment(row.booking.bookingDate).format("llll")}
                    </small>
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ width: "60px" }}
              ></div>

              <div className="textCenter-Custom">
                <div>{ClientLabel} Name</div>
                <div className="text-muted">{row.hospitalName}</div>
              </div>

              <div className="textCenter-Custom">
                <div>Submitted Date</div>
                <div className="text-muted">
                  {moment(row.submitedDate).format("llll")}
                </div>
              </div>

              <div>
                <Button
                  color="success"
                  className="mr-2"
                  onClick={() => {
                    toggleShowTimeModal(row);
                    setDocURL(row?.documentUrl);
                  }}
                >
                  Show
                </Button>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default PayrollBookingCard;
