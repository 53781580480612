import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useReschdulesFetch(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  employeeId
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [reset, query, searchAdvanced, employeeId]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: {
        page: pageNumber,
        offset: 10,
        q: query,
        employeeId: employeeId,
        sort: true,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.body.totalElements === 0) {
          setRows(() => []);
        }
        setTotalElements(res.data.body.totalElements);
        setRows((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.body.content])];
        });
        setHasMore(res.data.body.content.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber, reset, employeeId]);

  return { loading, error, rows, hasMore, totalElements };
}
