import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";
import moment from "moment";

export default function useBookingCancelFetch(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  status,
  getStartDate,
  getEndDate,
  getCancellationStartDate,
  getCancellationEndDate,
  shiftType
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced, reset]);

  useEffect(() => {
    const formattedStartDate = getStartDate
      ? moment(getStartDate).format("YYYY/MM/DD")
      : null;
    const formattedEndDate = getEndDate
      ? moment(getEndDate).format("YYYY/MM/DD")
      : null;

    const formattedCancellationStartDate = getCancellationStartDate
      ? moment(getCancellationStartDate).format("YYYY/MM/DD")
      : null;
    const formattedCancellationEndDate = getCancellationEndDate
      ? moment(getCancellationEndDate).format("YYYY/MM/DD")
      : null;

    const params = {
      ...(status && { status }),
      ...(formattedStartDate && { createdFrom: formattedStartDate }),
      ...(formattedEndDate && { createdTo: formattedEndDate }),

      ...(formattedCancellationStartDate && {
        startDate: formattedCancellationStartDate,
      }),
      ...(formattedCancellationEndDate && {
        endDate: formattedCancellationEndDate,
      }),

      ...(shiftType && { shiftType }),
      size: 10,
      page: pageNumber,
      search: query,
    };

    setLoading(true);
    setError(false);

    const source = axios.CancelToken.source();

    axios({
      method: "GET",
      url: url,
      params: params,
      cancelToken: source.token,
    })
      .then((res) => {
        if (res.data.status === "success") {
          let data = res.data.body.content;

          if (formattedStartDate || formattedEndDate) {
            data = data.filter((item) => {
              const bookingDate = new Date(item.bookingDate).getTime();
              const startDate = formattedStartDate
                ? new Date(formattedStartDate).getTime()
                : null;
              const endDate = formattedEndDate
                ? new Date(formattedEndDate).getTime()
                : null;

              return (
                (!startDate || bookingDate >= startDate) &&
                (!endDate || bookingDate <= endDate)
              );
            });
          }

          const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.shiftFrom).getTime();
            const dateB = new Date(b.shiftFrom).getTime();
            return dateA - dateB;
          });

          setTotalElements(res.data.body.totalElements);
          setHasMore(data.length > 0);
          setRows((prevRows) => [...new Set([...prevRows, ...sortedData])]);
        } else {
          setError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          setError(true);
        }
        setLoading(false);
      });

    return () => {
      source.cancel(
        "Request canceled due to component unmount or query change."
      );
    };
  }, [
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    status,
    getStartDate,
    getEndDate,
    shiftType,
  ]);

  return { loading, error, rows, hasMore, totalElements };
}
