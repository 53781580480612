import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import BookingSummery from "../../components/Modal/ModalBody/bookingSummery";
import ModalComponent from "../../components/Modal/modalComponent";
import ToastCommon from "../../components/toastCommon";
import { HOSTMexxar } from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import Delete from "../../components/Modal/ModalBody/delete";
import BookACandidate from "../../components/Modal/ModalBody/BookACandidate";

const ExternalShiftsCardWidget = (props) => {
  const { userProfile, reset } = props;

  const { addToast } = useToasts();

  const [rowId, setRowId] = useState([]);
  const [dayData, setDayData] = useState(null);

  const [showDeleteRow, setShowDeleteRow] = useState({ bool: false, id: "" });
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showCancleBooking, setShowCancleBooking] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  useDidMountEffect(() => {
    axios.get(HOSTMexxar + "candidates/" + rowId).then((res) => {
      if (res.data.status == "success") {
        setSelectedCandidate(res.data.body[0]);
      }
    });
  }, [rowId]);
  const deleteRow = () => {
    axios
      .delete(HOSTMexxar + "otherShift/" + showDeleteRow.id)
      .then((res) => {
        ToastCommon(true, "delete", addToast);
        reset();
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const refreshPage = () => {
    reset(); // Trigger data reload or reset function
  };
  return (
    <div className="">
      <div>
        <div className="card  list-row mb-2 ">
          <div className="list-item " data-id="7" data-sr-id="44">
            <div className="d-flex align-items-center mr-1 ">
              <span className="mx-1">
                <b className="badge badge-circle sm text-primary"></b>
              </span>
            </div>

            <div className="flex" style={{ flex: 4 }}>
              <a className="item-author text-color" data-pjax-state="">
                {userProfile.refNo}
              </a>
              <div>
                <small className="text-muted">{userProfile.clients.name}</small>
              </div>
              <div className="item-mail text-muted h-1x d-none d-sm-block">
                <span className="mr-1">
                  <small className="text-muted">
                    {moment(userProfile.date).format("ddd MMM DD YYYY")} by{" "}
                    {userProfile.employeeName}
                  </small>
                </span>
              </div>
            </div>

            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Start Time</div>

              <div>{userProfile.startTime.slice(0, -3)}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>End Time</div>
              <div>{userProfile.endTime.slice(0, -3)}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Ward</div>
              <div>{userProfile.ward}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Assignment No.</div>
              <div>{userProfile.assignment}</div>{" "}
              <div>{userProfile.secondaryAssignment}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Training</div>
              <div>{userProfile.training}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>
                <Button
                  className="mx-2 my-2"
                  color="success"
                  onClick={setShowBookingModal}
                >
                  Book
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    setRowId(userProfile.candidatesId);
                    setShowDeleteRow({ bool: true, id: userProfile.id });
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>

            <ModalComponent
              show={showCancleBooking}
              header="Booking Summary"
              closeModal={() => setShowCancleBooking(false)}
            >
              <BookingSummery
                // currentResetState={resetSheetData}
                reset={() => {
                  setShowCancleBooking(false);
                  reset();
                }}
                candidate={selectedCandidate}
                candidateId={Number(rowId)}
                dayData={dayData}
                heading="Booking summary"
                //  selectedRowData={selectedRowData}
                closeModal={() => setShowCancleBooking(false)}
                // reset={(e) => getAllRows(e)}
                // currentResetState={resetSheetData}
                incomingPage="BOOKING_WORKSPACE"
              />{" "}
            </ModalComponent>
            <ModalComponent
              show={showBookingModal}
              header="Booking A Candidate"
              closeModal={() => setShowBookingModal(false)}
            >
              <BookACandidate
                userProfile={userProfile}
                closeModal={() => {
                  setShowBookingModal(false);
                  refreshPage(); // Refresh after closing the modal
                }}
              />
            </ModalComponent>

            <Delete
              show={showDeleteRow.bool}
              isConfirm={() => {
                deleteRow();
                setShowDeleteRow({ bool: false, id: "" });
              }}
              closeModal={() => setShowDeleteRow({ bool: false, id: "" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalShiftsCardWidget;
