import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach, filter } from "lodash";
import Select from "react-select";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { EMPLOYEE_PERMISSIONS, HOSTMexxar } from "../../../configs/api-config";
import {
  Button,
  Label,
  Form,
  FormGroup,
  Col,
  Row,
  Input,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import {
  countiesGetAction,
  rolesGetAction,
  specialitiesGetAction,
  bandsGetAction,
} from "../../../redux/actions/initialDataGetAction";
import store from "../../../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import notNull from "../../../util/common/notNull";
import useDidMountEffect from "./intialRunUseEffect";
import useCandidateAssignToConsultant from "../../../customHooks/useCandidateAssignToConsultant";

 
function AssignCandidatesToConsultant(props) {
  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    workflowToBe,
    counties,
    specialities,
    roles,
    bands,
  } = props;

  const MexxarApiEmployee = EMPLOYEE_PERMISSIONS;

  const [headhunterData, setHeadhunterData] = useState(null);
  const [registerData, setRegisterData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);
  const [bookingConsultantData, setBookingConsultantData] = useState(null);

  const [county, setCounty] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [role, setRole] = useState([]);
  const [band, setBand] = useState([]);

  const [serviceType, setServiceType] = useState([
    {
      id: 0,
      value: "PERMANENT",
      label: "PERMANENT",
    },
    {
      id: 1,
      value: "TEMPORARY",
      label: "TEMPORARY",
    },
  ]);

  const [selectedCounty, setSelectedCounty] = useState({
    id: "",
    value: "",
    label: "",
  });

  const [selectedSpecialities, setSelectedSpecialities] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedRole, setSelectedRole] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedBand, setSelectedBand] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedServiceType, setSelectedServiceType] = useState({
    id: 1,
    value: "TEMPORARY",
    label: "TEMPORARY",
  });

  const [headunterName, setHeadunterName] = useState([]);
  const [registeresName, setRegisteresName] = useState([]);
  const [maintenacerName, setMaintenacerName] = useState([]);
  const [bookingConsultantName, setBookingConsultantName] = useState([]);

  const [start, setStart] = useState(false);

  const [consultantName, setConsultantName] = useState([]);

  let url = `${HOSTMexxar}candidates/filter/?status=${"UNVERIFIED"}&speciality=${
    selectedSpecialities.id
  }&role=${selectedRole.id}&band=${selectedBand.id}&serviceType=${
    selectedServiceType.value
  }${selectedCounty.id !== "" ? `&pc_county=${selectedCounty.id}` : ""}`;

  const { addToast } = useToasts();

  const onSubmit = () => {
    onCreate(registerData);
  };

  const getConsultantsList = () => {
    axios
      .all([
        axios.get(MexxarApiEmployee + "/permissions/BOOKING"),
        axios.get(MexxarApiEmployee + "/permissions/MAINTENANCE"),
        axios.get(MexxarApiEmployee + "/permissions/REGISTRATION"),
        axios.get(MexxarApiEmployee + "/permissions/HEADHUNTER"),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          let bookingConsultants = [];
          let maintenanceConsultants = [];
          let registrationConsultants = [];
          let headhuntingConsultants = [];

          // forEach(res1.data.body, function (item) {
          //   bookingConsultants.push({
          //     value: item.firstName + " " + item.lastName,
          //     label: item.firstName + " " + item.lastName,
          //     id: item.id,
          //   });
          // });
          // forEach(res2.data.body, function (item) {
          //   maintenanceConsultants.push({
          //     value: item.firstName + " " + item.lastName,
          //     label: item.firstName + " " + item.lastName,
          //     id: item.id,
          //   });
          // });
          // forEach(res3.data.body, function (item) {
          //   registrationConsultants.push({
          //     value: item.firstName + " " + item.lastName,
          //     label: item.firstName + " " + item.lastName,
          //     id: item.id,
          //   });
          // });
          forEach(res4.data.body, function (item) {
            headhuntingConsultants.push({
              value: item.firstName + " " + item.lastName,
              label: item.firstName + " " + item.lastName,
              id: item.id,
            });
          });
          setConsultantName(headhuntingConsultants);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  };

  const [showCandidateList, setShowCandidateList] = useState(false);
  const [noOfCandidate, setNoOfCandidate] = useState(10);

  const toggleCandidatesList = () => {
    setShowCandidateList(!showCandidateList);
  };

  const reArrangeToShowInSelectDropDown = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item,
        label: item,
        id: item,
      });
    });
    return reArranged;
  };

  const rearrangeSpecialities = () => {
    let specialitiesTemp = [];
    specialities.forEach((item) => {
      specialitiesTemp.push({
        value: item.name,
        label: item.name + `${item?.type == "DATABASE" ? "-DB" : ""}`,
        id: item.id,
      });
    });
    setSpeciality(specialitiesTemp);
  };
  const rearrangeRoles = () => {
    let rolesTemp = [];
    roles.forEach((item) => {
      rolesTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setRole(rolesTemp);
  };

  useEffect(() => {
    if (specialities) {
      rearrangeSpecialities();
    }
    return () => {};
  }, [specialities]);

  useEffect(() => {
    if (roles) {
      rearrangeRoles();
    }
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (bands) {
      rearrangeBands();
    }
    return () => {};
  }, [bands]);

  const rearrangeBands = () => {
    let bandsTemp = [];
    bands.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setBand(bandsTemp);
  };

  useEffect(() => {
    if (counties) {
      setCounty(reArrangeToShowInSelectDropDown(counties));
    }
    return () => {};
  }, [counties]);

  const { isLoading, results } = useCandidateAssignToConsultant(
    url,
    headhunterData?.id,
    noOfCandidate,
    start
  );

  useEffect(() => {
    getConsultantsList();
    store.dispatch(countiesGetAction());
    store.dispatch(specialitiesGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Col sm={12} md={6} lg={4}>
          <Label for="candidateRole">Role</Label>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            // isMulti
            id={"candidateRole"}
            value={selectedRole}
            name="candidateRole"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedRole(data)}
            options={role}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Label for="candidateBand">Band </Label>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            // isMulti
            value={selectedBand}
            id={"candidateBand"}
            name="candidateBand"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedBand(data)}
            options={band}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Label for="candidateSpeciality">Speciality </Label>
          <Select
            // isMulti
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            id={"candidateSpeciality"}
            value={selectedSpecialities}
            name="candidateSpeciality"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedSpecialities(data)}
            options={speciality}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} md={6} lg={4}>
          <Label for="county">County</Label>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            // isMulti
            value={selectedCounty}
            id={"county"}
            name="county"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedCounty(data)}
            options={county}
          />
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Label for="serviceType">Service Type</Label>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            // isMulti
            value={selectedServiceType}
            id={"serviceType"}
            name="serviceType"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedServiceType(data)}
            options={serviceType}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Label>No of Candidates</Label>
          <Input
            type="text"
            id="noOfCandidates"
            className="capitalize"
            name="noOfCandidates"
            value={noOfCandidate}
            onChange={(e) => setNoOfCandidate(e.target.value)}
            invalid={
              errors.noOfCandidates ||
              Number(noOfCandidate) > 99 ||
              isNaN(noOfCandidate)
            }
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 2,
                message: "This field should be less than 99!",
              },
              pattern: {
                value: /^\d+$/,
                message: "This field only accept numbers!",
              },
            })}
          ></Input>
          {!notNull(noOfCandidate) && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}

          {Number(noOfCandidate) > 99 && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                Please add a number below 99
              </li>
            </ul>
          )}
          {isNaN(noOfCandidate) && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field only accept numbers!
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={4}>
          <Label>Assign a Heahunter</Label>
          <Select
            // isMulti
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            name="consultants"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setHeadhunterData(data)}
            options={consultantName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} md={12} lg={12}>
          <Alert color="info">
            Please not that this filters only shows candidates who are UNVERIFIED and assign to
            Admin only or not assign to any other consultants
          </Alert>
          <div className="d-flex justify-content-end align-items-end">
            <Button
              className="mr-2"
              color="success"
              onClick={() => setStart(!start)}
            >
              Search
            </Button>
          </div>
        </Col>
      </FormGroup>
  

      <Row className="justify-content-end">
        <div className="d-flex ">
          <Button className="mr-2" color="success" type="submit">
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    counties: state.initialDataGetReducer.counties,
    specialities: state.initialDataGetReducer.specialities,
    roles: state.initialDataGetReducer.roles,
    bands: state.initialDataGetReducer.bands,
  };
}

export default connect(mapStateToProps, {})(AssignCandidatesToConsultant);
