import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { result, forEach, filter, find, uniqBy } from "lodash";
import useDidMountEffect from "./intialRunUseEffect";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Alert,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import {
  REGISTER_CANDIDATE,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  BOOKING_WORKSPACE_CANDIDATELIST,
  HOSTMexxar,
  ClientLable,
  CompanyName,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../configs/authConfig";
import { sendEmail } from "../../../pages/email/graph";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faHospital,
  faRandom,
  faSyncAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { sendNotification } from "../../../util/common/sendNotification";
import ModalComponent from "../modalComponent";
import ClientRateBookingModal from "./hospitalRateBookingWorkspace";
import store from "../../../redux/store";
import { gradesGetAction } from "../../../redux/actions/initialDataGetAction";

function AddBooking(props) {
  const { register, handleSubmit, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    dayData,
    candidateWhenClick,
    user,
    reduxGrades,
  } = props;

  const MexxarApi = HOSTMexxar;
  const MexxarShift = SHIFTS;
  const MexxarCandidate = REGISTER_CANDIDATE;
  const MexxarCreateBooking = CREATE_BOOKING;
  const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

  const { addToast } = useToasts();

  const [check, setCheck] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [likeWards, setLikeWards] = useState([]);
  const [candidates, setCandidates] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [likeClients, setLikeClients] = useState([]);
  const [currentShift, setCurrentShift] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedWard, setSelectedWard] = useState(0);
  const [wardsLoading, setWardsLoading] = useState(false);

  const [selectedSpcaility, setSelectedSpcaility] = useState(0);

  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [dateSelected, setDateSelected] = useState(dayData.date);
  const [showSpecialRates, setShowSpecialRates] = useState(false);
  const [sendConfirmation, setSendConfirmation] = useState(false);
  const [isCreatingBooking, setIsCreatingBooking] = useState(false);
  const [candidateSpecialities, setCandidateSpecialities] = useState();
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [duplicateValue, setDuplicateValue] = useState(false);
  const [breakMinutes, setBreakMinutes] = useState(0);
  const [currentGrade, setCurrentGrade] = useState(-1);
  const [grades, setGrades] = useState(null);

  const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
    useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState(candidateId);
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);
  const [refNo, setRefNo] = useState("");
  const [showHospitalRateModal, setShowHospitalRateModal] = useState(false);
  const [ratesConfirmed, setRatesConfirmed] = useState(false);
  const [rates, setRates] = useState([]);
  const [prefferedClientAdding, setPrefferedClientAdding] = useState({
    successBool: false,
    loadingBool: false,
    errorBool: false,
    showWarningMessage: true,
  });

  const [prefferedDepartmentAdding, setPrefferedDepartmentAdding] = useState({
    successBool: false,
    loadingBool: false,
    errorBool: false,
    showWarningMessage: true,
  });

  const onSubmit = (data) => {
    if (!refNo) {
      Alert("Automatically generate a booking reference...");
      return;
    }
    if (!dataErrorStatus) {
      addBooking(data);
    }
  };

  const { instance, accounts } = useMsal();

  //Function : sending the confirmation email to the candidate
  const sendBookingConfirmationEmail = (
    data,
    candidateWhenClick,
    selectedClient,
    selectedWard
  ) => {
    let subject = `Booking Confirmation: ${data?.referenceNo}`;
    let body = `<p>Hello ${candidateWhenClick?.value},</p>
                <p>We are writing to confirm your booking for ${moment(
                  data?.bookingDate
                ).format(
                  "ddd, DD-MM-YYYY"
                )} with ${CompanyName}. Please find the details below:</p>
                <ul>
                    <li>Reference no: ${data?.referenceNo}</li>
                    <li>Date: ${moment(data?.bookingDate).format(
                      "ddd, DD-MM-YYYY"
                    )}</li>
                    <li>${ClientLable}: ${selectedClient?.name}</li>
                    <li>Ward: ${selectedWard?.name}</li>
                    <li>Start time: ${moment
                      .utc(data?.shiftFrom)
                      .local()
                      .format("h:mm A")}</li>
                    <li>End time: ${moment
                      .utc(data?.shiftTo)
                      .local()
                      .format("h:mm A")}</li>
                </ul>
    `;

    let to = [
      {
        id: candidateWhenClick?.id,
        value: candidateWhenClick?.email,
        label: candidateWhenClick?.value,
      },
    ];
    let cc = [];
    let bcc = [];
    let file = [];

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendEmail(response?.accessToken, to, cc, bcc, subject, body, file, user)
          .then(() => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch(() => {});
      })
      .catch((error) => console.log(error));
  };

  const getwardsInSelectedClient = () => {
    setWardsLoading(true);
    axios
      .get(MexxarApi + "client-wards/clients/" + selectedClient.id)
      .then((res) => {
        let wardsList = [];
        forEach(res.data.body, function (ward) {
          let isPreferred = likeWards?.some((item) => item?.id === ward?.id);

          // Add each ward to the list with the isPreferred flag
          wardsList.push({
            id: ward.wardId,
            name: ward.wardName,
            label: ward.wardName,
            isPreferred: isPreferred,
            wardId: ward.id,
          });
        });
        setWardsLoading(false);

        setSelectedWard(wardsList[0]);
        setMatchingLikeWardsWithClient(wardsList);
      });
  };

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch(() => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  useEffect(() => {
    if (!reduxGrades) {
      store.dispatch(gradesGetAction());
    } else {
      setGrades(reduxGrades);
      setCurrentGrade(reduxGrades?.[0]?.id);
    }
  }, [reduxGrades]);

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
  }, []);

  useDidMountEffect(() => {
    getwardsInSelectedClient();
    return () => {};
  }, [selectedClient]);
  useEffect(() => {
    getDateField();
    return () => {};
  }, [
    dateSelected,
    selectedCandidates,
    candidateId,
    prefferedClientAdding.successBool,
  ]);

  // useEffect(() => {
  //   getDateField();
  // }, []);

  useDidMountEffect(() => {
    getData();
    return () => {};
  }, [selectedCandidates]);

  useEffect(() => {
    // getCandidates();
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    if (dayData.shift) {
      setCurrentShift(dayData.shift.id);
    } else {
      setCurrentShift(0);
    }
    return () => {};
  }, [dayData]);

  const [listOfNotEligibleClients, setListOfNotEligibleClients] = useState([]);
  const [listOfEligibleClients, setListOfEligibleClients] = useState([]);
  const [listOfFilterSelectedClients, setListOfFilterSelectedClients] =
    useState([]);

  const getData = () => {
    if (candidateId === undefined && selectedCandidates?.length === 0) {
      axios.get(MexxarShift).then((res3) => {
        setShifts(res3.data.body);
      });
    } else {
      axios
        .all([
          axios.get(MexxarShift),
          axios.get(MexxarCandidate + "/" + selectedCandidates),
        ])
        .then(
          axios.spread((res1, res2) => {
            const preferedClient = [];
            const activePreferredClients =
              res2.data.body[0].preferredClients.filter(
                (client) => client.status === "ACTIVE"
              );
            preferedClient.push(
              ...activePreferredClients.map((client) => ({
                id: client.id,
                name: client.name,
              }))
            );

            let specialities = [];
            res2.data.body[0].candidateSpecialities.forEach((speciality) => {
              specialities.push({
                id: speciality.id,
                name: speciality.name,
              });
            });

            setShifts(res1.data.body);
            setLikeWards(res2.data.body[0].likeWards);
            setLikeClients(preferedClient);
            setCandidateSpecialities(specialities);

            if (preferedClient.length > 0) {
              setSelectedClient(preferedClient[0]);
            }
            if (specialities.length > 0) {
              setSelectedSpcaility(specialities?.[0]?.id);
            }
          })
        )
        .catch((err) => {});
    }
  };

  const getDateField = () => {
    axios
      .get(
        MexxarApiBookingWorkspace +
          "?startDate=" +
          moment(dateSelected).format("YYYY/MM/DD") +
          "&endDate=" +
          moment(dateSelected).add(1, "days").format("YYYY/MM/DD")
      )
      .then((res) => {
        const body = res.data.body;

        let listOfCandidates = [];
        let eligibleClients = [];
        let notEligibleClients = [];

        for (let i = 0; i < body.length; i++) {
          const candidate = body[i].candidate;
          const candidateName = candidate.firstName + " " + candidate.lastName;
          listOfCandidates.push({
            value: candidateName,
            label: candidateName,
            id: candidate.id,
          });

          const bookingEligibilities = candidate.bookingEligibilities || [];
          bookingEligibilities.forEach((eligibility) => {
            const clientData = {
              clientId: eligibility.clients.id,
              clientName: eligibility.clients.name,
              candidateId: candidate.id,
              candidateName: candidateName,
            };
            if (eligibility.bookingEligibleStatus === "ELIGIBLE") {
              eligibleClients.push(clientData);
            } else if (eligibility.bookingEligibleStatus === "NOT_ELIGIBLE") {
              notEligibleClients.push(clientData);
            }
          });
        }

        var storedAgencyForFilters = JSON.parse(
          localStorage.getItem("selectedAgencyForFilter")
        );
        setListOfFilterSelectedClients(storedAgencyForFilters);
        setCandidates(listOfCandidates);
        setListOfEligibleClients(eligibleClients);
        setListOfNotEligibleClients(notEligibleClients);
      })
      .catch((err) => {});
  };

  const [notEligibleClientSelected, setNotEligibleClientSelected] =
    useState(false);

  const normalizedFilterSelectedClients = (
    listOfFilterSelectedClients || []
  ).map((client) => ({
    id: client.id,
    name: client.label || client.value || "Unknown", // Normalize name if label exists
    isFromFilterSelected: true, // Flag to differentiate from likeClients
  }));

  // Combine both lists and remove duplicates by `id`, prioritizing `likeClients`
  const mergedClients = uniqBy(
    [...likeClients, ...normalizedFilterSelectedClients],
    "id"
  );

  // Filtered and styled options
  const clientOptions = mergedClients?.map((client) => {
    // Eligibility checks for likeClients only
    const isNotEligible =
      !client.isFromFilterSelected &&
      listOfNotEligibleClients.some(
        (el) => el.clientId === client.id && el.candidateId === candidateId
      );
    const isEligible =
      !isNotEligible &&
      !client.isFromFilterSelected &&
      listOfEligibleClients.some(
        (el) => el.clientId === client.id && el.candidateId === candidateId
      );

    // Define the color based on source list
    const textColor =
      client.isFromFilterSelected &&
      !find(likeClients, { id: client.id }) &&
      "#dc3545";

    return {
      value: client.id,
      name: client.name,
      id: client.id,
      label: (
        <div
          style={{ display: "flex", alignItems: "center", color: textColor }}
        >
          <FontAwesomeIcon icon={faHospital} style={{ marginRight: "5px" }} />
          <div>{client.name}</div>
          {/* Eligibility and ineligibility icons */}
          {isEligible && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "green", marginLeft: "5px" }}
            />
          )}
          {isNotEligible && (
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ color: "red", marginLeft: "5px" }}
            />
          )}
          {/* Exclamation mark for unknown eligibility */}
          {client.isFromFilterSelected && !isEligible && !isNotEligible && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "orange", marginLeft: "5px" }}
            />
          )}
        </div>
      ),
      isDisabled: isNotEligible,
      isNotEligible,
      isFromFilterSelected: client.isFromFilterSelected,
    };
  });

  const addBooking = async (data) => {
    if (duplicateValue) {
      // Prevent form submission if duplicate value is detected
      ToastCommon(
        true,
        "duplicate",
        addToast,
        "The reference number already exists. Please change it before submitting."
      );
      return;
    }

    if (!refNo) {
      setDuplicateValue(true);
    } else {
      setDuplicateValue(false);
    }

    if (isCreatingBooking) return;

    setDisableCreateButton(true);
    setIsCreatingBooking(true);

    try {
      if (
        selectedClient &&
        clientOptions.some(
          (option) => option.value === selectedClient.id && option.isNotEligible
        )
      ) {
        setNotEligibleClientSelected(true);
        return;
      }

      // Prepare booking data
      const BStatus = check ? 1 : 0;
      const shiftStartingDate = moment(data.Booking_Date).format("YYYY-MM-DD");
      const shiftStartingTime = moment(data.Start_Time, "HH:mm").format(
        "HH:mm:ss"
      );
      const shiftEndingTime = moment(data.End_Time, "HH:mm").format("HH:mm:ss");
      const shiftSTime = moment(
        `${shiftStartingDate} ${shiftStartingTime}`
      ).toDate();
      const shiftETime = moment(
        `${shiftStartingDate} ${shiftEndingTime}`
      ).toDate();
      const currentDate = moment().toDate();

      const arrangData = {
        bookingDate: shiftStartingDate,
        bookingStatus: BStatus,
        bookingAttendedStatus: 4,
        createdDate: currentDate,
        referenceNo: data.Ref_No,
        candidateId: selectedCandidates,
        clientsId: selectedClient ? Number(selectedClient.id) : null,
        specialityId: Number(data.specialityId),
        shiftId: Number(data.shiftId),
        wardId: Number(selectedWard?.id),
        rateGradeId: Number(currentGrade), //use once its added
        // shiftTo: shiftETime,
        // shiftFrom: shiftSTime,
        shiftTo: moment(
          `${shiftStartingDate} ${endTime}`,
          "YYYY-MM-DD HH:mm"
        ).toDate(),
        shiftFrom: moment(
          `${shiftStartingDate} ${startTime}`,
          "YYYY-MM-DD HH:mm"
        ).toDate(),
        bonus: data.bonus !== undefined ? Number(data.bonus) : 0,
        upLift: data.upLift !== undefined ? Number(data.upLift) : 0,
        totalHours: data.totalHours !== undefined ? Number(data.totalHours) : 0,
        breakTime:
          data.breakTime !== undefined ? parseInt(data.breakTime, 10) : 0,
      };
      const refResponse = await axios.get(
        `${HOSTMexxar}bookings/reference/${data.Ref_No}`
      );

      if (refResponse?.data?.message === "Not Existing") {
        if (arrangData.totalHours !== 0) {
          const createResponse = await axios.post(
            MexxarCreateBooking,
            arrangData
          );
          if (createResponse?.data?.status === "success") {
            closeModal();

            if (sendConfirmation) {
              sendBookingConfirmationEmail(
                arrangData,
                candidateWhenClick,
                selectedClient,
                selectedWard
              );
            }
            const formattedShiftSTime = moment(shiftSTime).format(
              "dddd, MMMM D, YYYY, h:mm A"
            );
            const formattedShiftETime = moment(shiftETime).format("h:mm A");

            sendNotification({
              notificationType: "BOOKING",
              title: `New Booking Created: Reference Number ${data.Ref_No}`,
              content:
                `A new booking has been successfully created for ${shiftStartingDate}.\n` +
                `The scheduled shift will take place from:\n` +
                `${startTime} - ${endTime}.`,
              candidateId: selectedCandidates,
              startDate: shiftStartingDate,
              addToast: addToast,
            });

            ToastCommon(true, "success", addToast);
            onCreate(data);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        } else {
          ToastCommon(true, "setTime", addToast);
        }
      } else {
        setDuplicateValue(true);
        ToastCommon(true, "duplicate", addToast);
      }
    } catch (err) {
      ToastCommon(true, "duplicate", addToast);
    } finally {
      // Re-enable the create button and reset the guard clause
      setDisableCreateButton(false);
      setIsCreatingBooking(false);
    }
  };

  const [isGenerating, setIsGenerating] = useState(false);

  const generateUniqueRefNo = async () => {
    try {
      setIsGenerating(true);

      const randomRefNo = Math.floor(
        3000000000 + Math.random() * 7000000000
      ).toString();

      const refResponse = await axios.get(
        `${HOSTMexxar}bookings/reference/${randomRefNo}`
      );
      if (refResponse?.data?.message === "Existing") {
        generateUniqueRefNo();
      } else {
        setRefNo(randomRefNo);
        setDuplicateValue(false);
      }
    } catch (error) {
    } finally {
      setIsGenerating(false);
    }
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 5) {
        const time = `${String(hour).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        times.push(time);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isValid, setIsValid] = useState(true);

  // Function to handle time change and update total hours
  const handleTimeChange = (e, setTime, otherTime) => {
    let { value } = e.target;

    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    if (value.length === 2 && !value.includes(":")) {
      value = `${value}:`;
    }

    if (value.length >= 3 && parseInt(value.substring(0, 2), 10) > 23) {
      value = `23${value.substring(2)}`;
    }

    if (value.length === 5 && parseInt(value.substring(3), 10) > 59) {
      value = `${value.substring(0, 3)}59`;
    }

    setTime(value);

    // Optional: Validate the time input (if needed)
    const valid = validateTimeInput(value);
    setIsValid(valid);
  };

  const validateTimeInput = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  // Function to calculate time difference considering break time
  const calculateTotalHours = (start, end, breakMinutes = 0) => {
    if (!start || !end) return "00.00";

    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    let startDate = new Date();
    startDate.setHours(startHour);
    startDate.setMinutes(startMinute);

    let endDate = new Date();
    endDate.setHours(endHour);
    endDate.setMinutes(endMinute);

    let diffInMs = endDate - startDate;

    // If end time is earlier than start time, assume it's on the next day
    if (diffInMs < 0) {
      endDate.setDate(endDate.getDate() + 1);
      diffInMs = endDate - startDate;
    }

    // Subtract break minutes in milliseconds from total time difference
    const breakTimeInMs = breakMinutes * 60 * 1000;
    diffInMs -= breakTimeInMs;

    // Get hours and minutes from the updated time difference
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Get hours
    const diffInMinutes = Math.floor(
      (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
    ); // Get minutes

    // Format the result as "HH.MM"
    const formattedHours = String(diffInHours).padStart(2, "0");
    const formattedMinutes = String(diffInMinutes).padStart(2, "0");

    return `${formattedHours}.${formattedMinutes}`; // Return in "HH.MM" format
  };

  // Function to create options for react-select
  const createOptionsLikeWards = (matchingLikeWardsWithClient) => {
    return matchingLikeWardsWithClient?.map((item) => ({
      value: item.id,
      label: (
        <div style={{ color: !item?.isPreferred && "red" }}>
          {item.name}
          {!item?.isPreferred && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "orange", marginLeft: "5px" }}
            />
          )}
        </div>
      ),
    }));
  };

  const addLikeWard = async (ward) => {
    setPrefferedDepartmentAdding({
      successBool: false,
      loadingBool: true,
      errorBool: false,
      showWarningMessage: true,
    });
    let alreadyLikeList = likeWards.map((item) => item.id);

    let obj = {
      candidateId: candidateWhenClick.id,
      clientWards: [...alreadyLikeList, ward],
    };

    const res = await axios.put(HOSTMexxar + "candidates/liked-wards", obj);

    if (res?.data?.status === "success") {
      ToastCommon(true, "success", addToast);

      setPrefferedDepartmentAdding({
        successBool: true,
        loadingBool: false,
        errorBool: false,
        showWarningMessage: false,
      });
    } else {
      setPrefferedDepartmentAdding({
        successBool: false,
        loadingBool: false,
        errorBool: true,
        showWarningMessage: true,
      });
      ToastCommon(true, "error", addToast);
    }
    // Reset after a delay of, for example, 3 seconds (3000 ms)
    setTimeout(() => {
      setPrefferedDepartmentAdding({
        successBool: false,
        loadingBool: false,
        errorBool: false,
        showWarningMessage: false,
      });
    }, 2000);
  };

  const addPrefferedClient = async (client) => {
    setPrefferedClientAdding({
      successBool: false,
      loadingBool: true,
      errorBool: false,
      showWarningMessage: true,
    });
    let alreadyLikeList = likeClients.map((item) => item.id);
    let obj = {
      candidateId: candidateWhenClick.id,
      preferredClients: [...alreadyLikeList, client],
    };

    const res = await axios.put(
      HOSTMexxar + "candidates/preferred-clients",
      obj
    );

    if (res?.data?.status === "success") {
      ToastCommon(true, "success", addToast);

      setPrefferedClientAdding({
        successBool: true,
        loadingBool: false,
        errorBool: false,
        showWarningMessage: false,
      });
    } else {
      setPrefferedClientAdding({
        successBool: false,
        loadingBool: false,
        errorBool: true,
        showWarningMessage: true,
      });
      ToastCommon(true, "error", addToast);
    }
    // Reset after a delay of, for example, 3 seconds (3000 ms)
    setTimeout(() => {
      setPrefferedClientAdding({
        successBool: false,
        loadingBool: false,
        errorBool: false,
        showWarningMessage: false,
      });
    }, 2000);
  };

  useDidMountEffect(() => {
    setPrefferedClientAdding({
      successBool: false,
      loadingBool: false,
      errorBool: false,
      showWarningMessage: true,
    });
  }, [selectedClient]);
  useDidMountEffect(() => {
    setPrefferedDepartmentAdding({
      successBool: false,
      loadingBool: false,
      errorBool: false,
      showWarningMessage: true,
    });
  }, [selectedWard]);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={3}>Ref No</Label>

          <Col sm={8} className="mb-2">
            <Input
              type="text"
              placeholder="Ref No"
              name="Ref_No"
              id="Ref_No"
              value={refNo}
              invalid={errors.Ref_No}
              innerRef={register({
                required: "This field is required!",
                maxLength: {
                  value: 150,
                  message: "This field only allows up to 150 characters",
                },
              })}
              onChange={(e) => {
                const newValue = e.target.value;
                setRefNo(newValue);
                if (duplicateValue) setDuplicateValue(false);
              }}
            />
            {errors.Ref_No && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.Ref_No?.message}
                </li>
              </ul>
            )}
          </Col>

          <Col sm={1} className="d-flex justify-content-start">
            <Button
              color="link"
              onClick={() => {
                generateUniqueRefNo();
                setIsGenerating(true);
              }}
              style={{ padding: 0, border: "none" }}
              id="random"
            >
              <FontAwesomeIcon
                icon={faSyncAlt}
                size="lg"
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
            </Button>

            <UncontrolledTooltip target="random">
              <small>Generate random number</small>
            </UncontrolledTooltip>
          </Col>

          {duplicateValue && (
            <>
              <Col sm={12}>
                <Alert color="danger">
                  The reference number already exists in the CRM. Please use a
                  different reference number or generate a random one above.
                </Alert>
              </Col>
            </>
          )}

          {!duplicateValue && !isGenerating && (
            <Col sm={12}>
              <Alert color="info">
                Automatically generate a booking reference if the shift does not
                have a reference number or if the reference already exists in
                the CRM.
              </Alert>
            </Col>
          )}
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Date</Label>
          <Col sm={8}>
            <Input
              defaultValue={
                dayData.date !== undefined && dayData.date !== null
                  ? moment(dayData.date).format("DD/MM/YYYY") // Display format
                  : ""
              }
              type="text"
              invalid={errors.date || dataErrorStatus}
              placeholder="Date"
              name="Booking_Date_Display"
              readOnly
            />
            {/* Hidden input to submit the original value */}
            <Input
              defaultValue={dayData.date || ""}
              type="hidden"
              name="Booking_Date"
              innerRef={register}
            />
            {dataErrorStatus && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">Invalid Date</li>
              </ul>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Shift Type</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="shiftId"
              name="shiftId"
              defaultValue={currentShift}
              value={currentShift}
              invalid={!!errors.shiftId}
              innerRef={register({
                required: "Shift Type is required",
                validate: (value) =>
                  value !== "0" || "Please select a shift type",
              })}
              onChange={(e) => setCurrentShift(Number(e.target.value))}
            >
              <option value="0">Select</option>
              {shifts?.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Input>

            {errors.shiftId && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.shiftId?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Start Time</Label>
          <Col sm={8}>
            <Input
              list="time-options"
              type="text"
              name="Start_Time"
              placeholder="Enter time (HH:MM)"
              value={startTime}
              onChange={(e) => handleTimeChange(e, setStartTime, endTime)}
            />
            <datalist id="time-options">
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </datalist>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>End Time</Label>
          <Col sm={8}>
            <Input
              list="time-options"
              type="text"
              name="End_Time"
              placeholder="Enter time (HH:MM)"
              value={endTime}
              onChange={(e) => handleTimeChange(e, setEndTime, startTime)}
            />
            <datalist id="time-options">
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </datalist>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={3}>
            <Label>Tag Candidate</Label>
          </Col>
          <Col sm={8}>
            <Select
              defaultValue={candidateWhenClick ? candidateWhenClick : ""}
              name="employeeId"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedCandidates(data.id)}
              isSearchable={false}
              // options={candidates}
              // innerRef={register({
              //   required: {
              //     value: true,
              //     message: "This field is required!",
              //   },
              // })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>{ClientLable}</Label>
          <Col sm={8}>
            <Select
              id="clientId"
              name="clientId"
              value={clientOptions.find(
                (option) => option.value === selectedClient?.id
              )}
              onChange={(selectedOption) => {
                setSelectedClient({
                  id: selectedOption.value,
                  name: selectedOption.label.props.children[1],
                });

                setNotEligibleClientSelected(false);
              }}
              options={clientOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isInvalid={!!errors.clientId}
            />

            {notEligibleClientSelected && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                This client is not eligible for booking
              </div>
            )}
            {!find(likeClients, { id: selectedClient.id }) &&
              prefferedClientAdding.showWarningMessage && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {ClientLable} is not in preferred {ClientLable} list. Would
                    you like to add?{" "}
                    <span
                      className="text-info pointer"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.textDecoration = "none")
                      }
                      onClick={() => addPrefferedClient(selectedClient.id)}
                    >
                      Add as preferred
                      {prefferedClientAdding.loadingBool && (
                        <Spinner
                          animation="border"
                          className="mx-2"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {prefferedClientAdding.errorBool && (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="mx-2 text-danger"
                        />
                      )}
                      {prefferedClientAdding.successBool && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mx-2 text-success"
                        />
                      )}
                    </span>
                  </li>
                </ul>
              )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>
            Department{" "}
            <FontAwesomeIcon
              id="department"
              className="text-warning"
              icon={faExclamationCircle}
            />
          </Label>
          <Col sm={8}>
            <Select
              id="departmentId"
              isLoading={wardsLoading}
              value={selectedWard}
              options={createOptionsLikeWards(matchingLikeWardsWithClient)}
              onChange={(selectedOption) => {
                const selectedWard = matchingLikeWardsWithClient.find(
                  (item) => item.id === selectedOption.value
                );
                setSelectedWard(selectedWard);
              }}
            />
            <UncontrolledTooltip target={"department"}>
              <small>
                Note: The selected {ClientLable} or department is not in the
                candidate's preferred lists. Please ensure to call the candidate
                before making a booking.
              </small>
            </UncontrolledTooltip>

            {!selectedWard?.isPreferred &&
              prefferedDepartmentAdding.showWarningMessage && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    Department is not in like Department List. Would you like to
                    add?{" "}
                    <span
                      className="text-info pointer"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.textDecoration = "none")
                      }
                      onClick={() => addLikeWard(selectedWard.wardId)}
                    >
                      Add as like
                      {prefferedDepartmentAdding.loadingBool && (
                        <Spinner
                          animation="border"
                          className="mx-2"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {prefferedDepartmentAdding.errorBool && (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="mx-2 text-danger"
                        />
                      )}
                      {prefferedDepartmentAdding.successBool && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mx-2 text-success"
                        />
                      )}
                    </span>
                  </li>
                </ul>
              )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Candidate Specialities</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="specialityId"
              name="specialityId"
              // invalid={errors.wardId}

              value={selectedSpcaility}
              onChange={(data) => setSelectedSpcaility(data.target.value)}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {candidateSpecialities &&
                candidateSpecialities.map((item, id) => {
                  return (
                    <option value={item.id} key={id}>
                      {item.name}
                    </option>
                  );
                })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Grade</Label>
          <Col sm={8}>
            <Input
              type="select"
              name="grade"
              defaultValue={currentGrade && currentGrade}
              innerRef={register}
              onChange={(selected) => setCurrentGrade(selected.target.value)}
            >
              <option value="-1">Select</option>
              {grades &&
                grades?.map((grade, key) => (
                  <option value={grade.id} key={key}>
                    {grade.name} - {grade.escalation && "Escalation"}
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Send Confirmation</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="sendConfirmation"
                  className="pointer"
                  value={sendConfirmation}
                  checked={sendConfirmation}
                  onClick={() => setSendConfirmation(!sendConfirmation)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        {showSpecialRates ? (
          <FormGroup row>
            <Label sm={3}>Special Rate (£)</Label>

            <Col sm={4}>
              <Input
                type="text"
                placeholder="Up lift"
                name="upLift"
                invalid={errors.upLift}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.upLift && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.upLift?.message}
                  </li>
                </ul>
              )}
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                placeholder="Bonus"
                name="bonus"
                invalid={errors.bonus}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.bonus && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.bonus?.message}
                  </li>
                </ul>
              )}
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup row>
          <Label sm={3}>Break Minutes</Label>
          <Col sm={8}>
            <Input
              type="text"
              placeholder="Break Minutes"
              name="breakTime"
              value={breakMinutes}
              innerRef={register}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                setBreakMinutes(Number(value)); // Update breakMinutes value
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Total Hours</Label>
          <Col sm={8}>
            <Input
              type="text"
              placeholder="Total Hours"
              name="totalHours"
              innerRef={register}
              value={
                startTime && endTime
                  ? calculateTotalHours(startTime, endTime, breakMinutes)
                  : ""
              }
              readOnly // Make it read-only
            />
          </Col>
        </FormGroup>

        <hr />
        {!outlookLoggedIn && sendConfirmation && (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <p>
              To send a booking confirmation via email, kindly ensure that you
              are logged in first
            </p>
            <img src={outlookLogo} style={{ height: 100 }}></img>
            <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
              Login
            </u>
          </Alert>
        )}
        <Alert
          className="d-flex align-items-center justify-content-center flex-column "
          color="info"
        >
          Please make sure to confirm the pay rates before making the booking.
        </Alert>

        <div className="d-flex justify-content-end">
          <Button
            color={ratesConfirmed ? "info" : "warning"}
            className="mr-2"
            onClick={() => setShowHospitalRateModal(!showHospitalRateModal)}
          >
            {ratesConfirmed ? "Show Rates" : "Add Pay Rates"}
          </Button>

          {!sendConfirmation && ratesConfirmed && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={disableCreateButton}
            >
              Create
            </Button>
          )}

          {sendConfirmation && ratesConfirmed && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={
                disableCreateButton || !outlookLoggedIn || duplicateValue
              }
            >
              Create
            </Button>
          )}
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>

      <ModalComponent
        className="modal-dialog modal-lg"
        show={showHospitalRateModal}
        header="Candidate Rate"
        closeModal={() => setShowHospitalRateModal(false)}
      >
        <ClientRateBookingModal
          incommingPage="addBooking"
          clientOptions={clientOptions}
          grade={currentGrade}
          candidate={candidateWhenClick}
          closeModal={() => setShowHospitalRateModal(false)}
          clientId={selectedClient.id}
          specialityId={selectedSpcaility}
          departmentId={selectedWard ? selectedWard?.id : -1}
          confirmRates={(data) => {
            setRates(data);
            setRatesConfirmed(true);
            setShowHospitalRateModal(false);
          }}
          ratesConfirmed={ratesConfirmed}
        />
        {/*<ClientRateModal toggle={()=> setShowHospitalRateModal(!showHospitalRateModal)} client={{id : 1}}></ClientRateModal>*/}
      </ModalComponent>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.auth.item,
    reduxGrades: state.initialDataGetReducer.grades,
  };
}

export default connect(mapStateToProps, {})(AddBooking);
