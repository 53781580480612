import React, { useState } from "react";

import { connect } from "react-redux";
import CandidateProfilePayrollUploadedWidget from "../../../widgets/payroll/candidate-profile-payroll-uploaded-widget";

const PayrollSummery = (props) => {
  const { auth, candidateId, candidate, reload } = props;

  return (
    <>
      <CandidateProfilePayrollUploadedWidget
        userType={auth.item.userType}
        candidateId={candidateId}
        candidate={candidate}
        reload={reload}
      ></CandidateProfilePayrollUploadedWidget>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(PayrollSummery);
