import React, { useEffect, useState } from "react";

import axios from "axios";
import Previews from "../audit/docView/Previews";
import TimesheetData from "./TimesheetData";
import {
  Row,
  Col,
  FormGroup,
  Container,
  UncontrolledTooltip,
  Button,
  Spinner,
  Label,
  Badge,
  Alert,
} from "reactstrap";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { HOSTMexxar } from "../../configs/api-config";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import PayrollSummary from "./PayrollSummary";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import {
  addAITimesheetData,
  addSystemTimesheetData,
  deleteAllTimesheetsLocally,
  saveTimesheetsLocally,
  timesheetExistStatus,
  updateScanProcessStatus,
  logFailedTimesheets,
  scanStatusIndividual,
  finalManualReviewConfirmation,
  updates3BucketFilrUrl,
  preVerifiedStatusMobile,
} from "../../redux/actions/mobileTimesheetBatchScanAction";
import PreviewsBatchTimeSheetsForMobileUploads from "../audit/docView/PreviewsBatchTimeSheetsForMobileUploads";
import * as XLSX from "xlsx";
// import TimesheetDataBatchView2 from "./TimesheetDataBatchView2";
// import TimeSheetDataBatchView2 from "./TimeSheetDataBatchView2";
// import TimesheetDataBatchView2 from "./TimesheetDataBatchView2";
import CandyMobileTimeSheetBatchView from "./CandyMobileTimeSheetBatchView";
import { saveMobileTimesheetsLocally } from "../../redux/actions/mobileTimesheetBatchScanAction";
import PendingMobileTimesheetsforVerification from "./pendingMobileTimesheetsforVerification";
import FileDownloaderForMobile from "./FileDownloaderForMobile";

function MobileTimeSheetBatchScan(props) {
  const {
    timesheets,
    scanProcessingStatus,
    failedTimesheets,
    finalManualReview,
    exportObject,
  } = props;
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [processLoading, setProcessLoading] = useState(null);
  const [incommingAIdata, setIncommingAIdata] = useState(null);
  const [systemTimeSheetData, setSystemTimeSheetData] = useState(null);
  const [timeSheetFalse, setTimeSheetFalse] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [payrollData, setPayrollData] = useState(null);
  const [payrollError, setPayrollError] = useState(null);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [review, setReview] = useState([]);
  const [selectedFileForReview, setSelectedFileForReview] = useState(null);
  const [savedRefNo, setSavedRefNo] = useState(null);
  const [savedRefNo2, setSavedRefNo2] = useState(null);
  const [ref3, setRef3] = useState(null);

  const handleChangeStatus = (files) => {
    //if the user navigates to some other page and returns the data should remain same as before
    if (timesheets.length > 0) {
      return;
    }

    setSelectedFiles(files);

    //adding timeSheetExist: false, AIModalData: {}, DBData: {}, to each file
    const updatedFiles = files.map((file, index) => {
      const oneSecond = 1000; // 1 second in milliseconds
      const newDateTime = new Date(Date.now() + index * oneSecond); // Add index * 1 second

      return {
        file: file,
        timeSheetExist: false,
        AIModalData: {},
        DBData: {},
        AIModalDataLoadingStatus: {},
        DBData: {},
        manuallyReviewed: false,
        generatedUrl: "",
        s3bucketfileurl: "",
        timesheetReviewDateTime: newDateTime,
        aIReviewStatus: "NOT_STARTED",
        reviewedData: {},
        scanStatus: {
          status: "NOT_STARTED",
          message: "",
        },
      };
    });
    dispatch(saveMobileTimesheetsLocally(updatedFiles));
  };

  const checkTimesheetProcessStatus = async (query, start, end) => {
    try {
      // Format dates
      const startDate = moment(start).format("YYYY/MM/DD");
      const endDate = moment(end).format("YYYY/MM/DD");

      // Build URL
      const url = `${HOSTMexxar}workspace/payrollfilter?ps=PENDING&hrs=PENDING&rs=VERIFIED`;

      // Perform the request
      const response = await axios.get(url, {
        params: {
          q: query,
          start: startDate,
          end: endDate,
        },
      });

      // Check response data
      return {
        data:
          response.data.body?.content?.length > 0
            ? response.data.body?.content
            : {},
        bool: response.data.body?.content?.length > 0,
      };
    } catch (error) {
      console.error(error);
      return { data: {}, bool: false };
    }
  };

  const getTimesheetFromSystem = (data, file) => {
    console.log("getTimesheetFromSystem----------------->");
    if (data) {
      axios
        .get(HOSTMexxar + "bookings/reference/" + data)
        .then((res) => {
          console.log("getTimesheetFromSystem Response:", res.data);

          setSystemTimeSheetData(res.data.body);
          dispatch(addSystemTimesheetData(data, res.data.body));

          if (res.data.message === "Not Existing") {
            setTimeSheetFalse(true);
            setSavedRefNo2(data);

            console.log("Before retry, reference number saved:", savedRefNo2);
            dispatch(timesheetExistStatus(file.preview, false));
          } else if (res.data.message === "fetched") {
            setTimeSheetFalse(false);
            dispatch(timesheetExistStatus(file.preview, true));

            setSavedRefNo(data);
            // Call checkTimesheetProcessStatus and handle the result
            const query = res.data?.body?.referenceNo;
            const shiftFrom = moment(res.data?.body?.shiftFrom);
            const shiftTo = moment(res.data?.body?.shiftTo);

            const start = shiftFrom.subtract(1, "days").format("YYYY/MM/DD");
            const end = shiftTo.add(1, "days").format("YYYY/MM/DD");

            checkTimesheetProcessStatus(query, start, end)
              .then((statusResult) => {
                if (statusResult.bool) {
                  dispatch(
                    preVerifiedStatusMobile(
                      file?.preview,
                      "PRE_VERIFIED",
                      "This timesheet has been pre-verified"
                    )
                  );
                } else {
                  dispatch(
                    preVerifiedStatusMobile(file?.preview, "NOT_VERIFIED", "")
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Error fetching timesheet process status:",
                  error
                );
              });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const refNoSet = (data, file) => {
    const bookingRefData = data.bookingref;

    if (bookingRefData && bookingRefData.value) {
      const bookingRef = bookingRefData.value;
      // Call the necessary functions with the booking reference
      getTimesheetFromSystem(bookingRef, file);
      // ... rest of your logic
    } else {
      console.error("Booking Reference not found in data:", data);
    }
  };

  const [bookingRefNumber, setBookingRefNumber] = useState(null);

  const getRefNo2 = (data) => {
    const bookingref = data.bookingref;

    console.log("dj2", bookingref);
    const ref = data[bookingref + "Ref"].value;
    console.log("dj", ref);

    setBookingRefNumber(ref);

    console.log("previously scanned ref no:", bookingRefNumber);

    return ref;
  };

  const prepareAIData = (data, file) => {
    console.log("data:", data);
    let tempArray = [];
    tempArray.push([data]);

    let arrangeData = {};

    var fullName = data.locumName;
    var nameArray = fullName.split(" ");

    var firstName = nameArray[0];
    var lastName = nameArray[1];

    arrangeData["candidatename"] = data.locumName;
    arrangeData["consultantname"] = "Candidate Uploaded";
    arrangeData["firstname"] = firstName || "";
    arrangeData["band"] = data.band;
    arrangeData["clientname"] = data.hospitalName;
    arrangeData["type"] = data.type || ""; //This is the speciality
    arrangeData["date"] = data.dateOfShift || "";
    arrangeData["endtime"] = data.endTime || "";
    arrangeData["bookingref"] = data.bookingRef || "";
    arrangeData["appraisal"] = data.appraisal || "";
    arrangeData["starttime"] = data.startTime || "";
    arrangeData["ward"] = data.ward || "";
    arrangeData["candidateposition"] = data.candidateposition || "";
    arrangeData["break"] = data.minsTakenForBreaks || "";
    arrangeData["surname"] = lastName || "";
    arrangeData["totalhours"] = data.totalHours || "";
    arrangeData["day"] = moment(data.dateOfShift).format("ddd") || "";
    arrangeData["clientauthname"] = data.authoriseName || "";

    // arrangeData["Breakmin"] = data.minsTakenForBreaks || "";
    // arrangeData["Date"] = moment(data.dateOfShift , "DD/MM/YYYY").format( "YYYY-MM-DD" );
    // arrangeData["Day"] = moment(data.dateOfShift).format("ddd") || "";
    // arrangeData["End"] = data.endTime || "";
    // arrangeData["Ref"] = data.bookingRef || "";
    // arrangeData["Start"] = data.startTime || "";
    // arrangeData["Totalhours"] = data.totalHours || "";
    // arrangeData["Ward"] = data.ward || "";
    // arrangeData["Band"] = data.band || "";
    // arrangeData["HospitalName"] = data.hospitalName;
    // arrangeData["CandidateId"] = "";
    // arrangeData["CandidateName"] =  data.locumName;
    // arrangeData["Speciality"] = data.type || ""; //This is the speciality

    setIncommingAIdata(arrangeData);

    dispatch(addAITimesheetData(file.bookingRef, arrangeData));
  };

  const processTimeSheet = async (file) => {
    setProcessLoading(true);

    const fd = new FormData();
    fd.append("file", file);

    axios({
      method: "post",
      url: "https://time.mexxar.com/uploadtimesheet/",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      data: fd,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success") {
          refNoSet(res.data.body, file);
          prepareAIData(res.data.body, file);
        }

        setProcessLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        setProcessLoading(false);
      });
  };

  const processBatchTimeSheet = async () => {
    let noOfFiles = selectedFiles?.length;

    for (let i = 0; i < noOfFiles; i++) {
      await processTimeSheet(selectedFiles[i]);
    }
  };

  // useEffect(() => {
  //   dispatch(deleteAllTimesheetsLocally());
  // }, []);

  ///new method

  // Process timesheets sequentially
  const processTimesheetsSequentially = async (timesheets) => {
    setSelectedFileForReview(null);
    dispatch(updateScanProcessStatus("SCANNING"));
    setProcessLoading(true);
    // getBucketUrl();

    try {
      for (let i = 0; i < timesheets.length; i++) {
        await processSingleTimesheet(timesheets[i].file);
      }

      dispatch(updateScanProcessStatus("DONE"));
    } catch (error) {
      dispatch(updateScanProcessStatus("INTERRUPTED"));
    }

    setProcessLoading(false);
  };

  // Get file url in S3 Bucket //

  const saveBookingRef = (data) => {
    if (data && data.bookingref && data.bookingref.value) {
      const bookingRef = data.bookingref.value;
      setBookingRefNumber(bookingRef);
      console.log("Booking ref saved:", bookingRef);
      return bookingRef;
    } else {
      return null;
    }
  };

  // const processSingleTimesheet = async (file) => {
  //   dispatch(scanStatusIndividual(file?.preview, "SCANNING", ""));
  //   try {
  //     const fd = new FormData();
  //     fd.append("file", file, file.name);
  //     console.log("checkfile name", file);

  //     const scanResponse = await axios({
  //       method: "post",
  //       url: "https://time.mexxar.com/uploadtimesheet/candy",
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //       },
  //       data: fd,
  //       mode: "cors",
  //     });

  //     if (scanResponse.data.status === "success") {
  //       const bookingRef =saveBookingRef(scanResponse.data.body)

  //       console.log ("salalalalaalala", bookingRef)
  //       prepareAIData(scanResponse.data.body, file);

  //       dispatch(scanStatusIndividual(file?.preview, "DONE", "success"));

  //       const s3UploadFormData = new FormData();

  //        const referenceNumber = scanResponse.data.body;
  //             s3UploadFormData.append('file', file);
  //         s3UploadFormData.append('fileName',  bookingRef);

  //       const s3UploadResponse = await axios.post('https://time.mexxar.com/timesheet/file/upload', s3UploadFormData, {
  //         params: {
  //              bucketName: 'mexxar-timesheet',
  //               folderName: 'mex_candy',
  //                   },
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //         },
  //         mode: "cors",
  //       });

  //       if (s3UploadResponse.status === 200) {

  //         refNoSet(scanResponse.data.body, file);
  //      prepareAIData(scanResponse.data.body, file);
  //         dispatch(scanStatusIndividual(file?.preview, "DONE", "success"));

  //          } else {
  //           dispatch(logFailedTimesheets(file, "S3 upload failed"));
  //                   dispatch(scanStatusIndividual(file?.preview, "FAILED", "S3 upload failed"));
  //           }
  //     }

  //     else {
  //       dispatch(logFailedTimesheets(file, scanResponse.data.message));
  //       dispatch(scanStatusIndividual(file?.preview, "FAILED", scanResponse.data.message));
  //     }
  //   } catch (error) {
  //     dispatch(logFailedTimesheets(file, error.message));
  //     dispatch(scanStatusIndividual(file?.preview, "FAILED", error.message));
  //   }
  // };

  const processSingleTimesheet = async (file) => {
    console.log("angi processSingleTimesheet", file);

    prepareAIData(file, file);
    setSystemTimeSheetData(file?.booking);
    dispatch(addSystemTimesheetData(file?.bookingRef, file?.booking));

    setSavedRefNo(file?.bookingRef);
    dispatch(scanStatusIndividual(file?.bookingRef, "DONE", "success"));

    if (file?.bookingRef) {
      setTimeSheetFalse(false);
      dispatch(timesheetExistStatus(file?.bookingRef, true));
    } else {
      setTimeSheetFalse(true);
      dispatch(timesheetExistStatus(file?.bookingRef, false));
    }
    // Call checkTimesheetProcessStatus and handle the result
    const query = file?.bookingRef;
    const shiftFrom = moment(file?.dateOfShift);
    const shiftTo = moment(file?.dateOfShift);

    const start = shiftFrom.subtract(1, "days").format("YYYY/MM/DD");
    const end = shiftTo.add(1, "days").format("YYYY/MM/DD");
    // console.log("angi checking the place---->",query, start, end,file)
    checkTimesheetProcessStatus(query, start, end)
      .then((statusResult) => {
        if (statusResult.bool) {
          // console.log("angi timesheet updating the status----?",statusResult)
          dispatch(
            preVerifiedStatusMobile(
              file?.bookingRef,
              "PRE_VERIFIED",
              "This timesheet has been pre-verified"
            )
          );
        } else {
          dispatch(
            preVerifiedStatusMobile(file?.bookingRef, "NOT_VERIFIED", "")
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching timesheet process status:", error);
      });
  };

  const getBucketUrl = async (ref) => {
    try {
      const response = await axios.get(
        "https://time.mexxar.com/timesheet/url",
        {
          params: {
            fileName: ref,
            folderName: "mex_candy",
            linkDuration: "10",
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          mode: "cors",
        }
      );

      if (response.status === 200) {
        console.log("URL generated successfully", response.data);
      } else {
        console.error("Failed to generate URL", response.status);
        console.error("Error message:", response.data);
      }
    } catch (error) {
      console.error("Error generating URL:", error.message);
    }
  };

  const toggleReview = (data) => {
    const index = data.file;
    // Toggle to review
    if (review.includes(index.bookingRef)) {
      setReview(review.filter((rowIndex) => rowIndex !== index.bookingRef));
      setSelectedFileForReview(null);
    } else {
      setReview([index.bookingRef]);
      setSelectedFileForReview(data);
    }
  };

  const extractReviewedData = () => {
    return timesheets.map((obj) => obj.reviewedData);
  };

  //********************* Export data to excel sheet *********************//
  console.log("angi export to excel data 01------>", exportObject);

  const exportToExcel = () => {
    const updatedData = exportObject.map(({ SplitRate, ...rest }) => rest);
    console.log("angi export to excel data 22", exportObject);

    // Filter out rows with empty data
    const nonEmptyRows = updatedData.filter((row) =>
      Object.values(row).some((cell) => cell !== "")
    );

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(nonEmptyRows);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the Excel file and download it
    XLSX.writeFile(workbook, "reviewed_data.xlsx");
  };

  const restart = () => {
    dispatch(deleteAllTimesheetsLocally());
    setSelectedFileForReview(null);
  };

  const [timesheetImageList, setTimesheetImageList] = useState([]);

  const prepareTimesheetUrlList = () => {
    let listOfTimesheetUrls = timesheets
      .filter((x) => !isEmpty(x.reviewedData)) // Filter out objects with non-empty reviewedData
      .map((x) => ({
        url:
          HOSTMexxar +
          "timesheet-doc/candidates/" +
          x.DBData.candidatesId +
          "/" +
          x.file?.documentUrl?.split("/")[1],
        bookingRef: x.reviewedData?.Reference,
      }));
    setTimesheetImageList(listOfTimesheetUrls);
  };

  //clear the selected candidate when changing the reviewing timehsheet
  useDidMountEffect(() => {
    setSelectedCandidate(null);

    return () => {};
  }, [review]);

  useDidMountEffect(() => {
    setSystemTimeSheetData(null);
    setIncommingAIdata(null);

    return () => {};
  }, [selectedFiles, showSummary]);

  useDidMountEffect(() => {
    if (isNull(incommingAIdata) && isNull(systemTimeSheetData)) {
      setSelectedFiles(null);
      setDocumentUploaded(!documentUploaded);
    }
    return () => {};
  }, [showSummary]);

  const SortFunction = (a, b) => {
    var dateA = new Date(a.timesheetReviewDateTime).getTime();
    var dateB = new Date(b.timesheetReviewDateTime).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <div
      className="  m-3 p-3 "
      // className="d-flex p-2 m-2 align-items-between justify-content-center "
      style={{ width: "100%", overflowX: "hidden" }}
    >
      <div className="row justify-content-around ">
        <div
          className="  navbar-expand-lg "
          style={{ position: "fixed", top: "50px", left: "50px", zIndex: 999 }}
        >
          <Link
            to={{
              pathname: "/dashboard/payroll",
            }}
          >
            <div className="btn btn-sm btn-icon btn-rounded btn-raised  bg-dark-overlay ">
              <FontAwesomeIcon
                className="feather feather-more-vertical text-fade"
                icon={faChevronLeft}
              />
            </div>
          </Link>
        </div>

        <div class="card p-3  col-sm-12 col-md-6 d-flex align-items-between justify-content-start h-80 mt-4">
          <Alert color="danger">
            <b> Caution :</b> Please do not refresh the page - If you have not
            finished the reviewing process, refreshing will result in losing
            your reviewed data, which will have to be reviewed from the start.
          </Alert>
          {timesheets?.length > 0 ? (
            <>
              <Button
                className="m-2"
                disabled={scanProcessingStatus == "SCANNING"}
                color={`${timeSheetFalse ? "danger" : "success"}`}
                onClick={() => {
                  // processBatchTimeSheet();
                  processTimesheetsSequentially(timesheets);
                }}
              >
                {scanProcessingStatus == "SCANNING" && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {scanProcessingStatus == "SCANNING" && "Processing"}
                {scanProcessingStatus == "INTERRUPTED" && "Retry"}
                {scanProcessingStatus == "NOT_STARTED" && "Process"}
                {scanProcessingStatus == "DONE" && (
                  <>
                    <FontAwesomeIcon icon={faCheck} className="mx-2" />{" "}
                    Reprocess
                  </>
                )}
              </Button>
              {scanProcessingStatus == "SCANNING" && (
                <Button onClick={restart}>Stop</Button>
              )}
              {scanProcessingStatus == "SCANNING" ||
                (scanProcessingStatus == "DONE" && (
                  <Button onClick={restart}>Clear</Button>
                ))}
            </>
          ) : (
            <div class="card p-3 ml-4 mt-4 col-sm-12 col-md-6 d-flex align-items-between justify-content-center  ">
              <div>
                Hurray!!!
                <br /> You don't have any timesheets to review.{" "}
              </div>
            </div>
          )}
          <PreviewsBatchTimeSheetsForMobileUploads
            showFile={selectedFileForReview}
            expandStatus={expand}
            fileUploaded={(data) => setFileUploaded(data)}
            onUpload={(data) => handleChangeStatus(data)}
            documentUploaded={documentUploaded}
            showUploadSection={scanProcessingStatus !== "SCANNING"}
            review={review}
          />
        </div>
        <div class="col-sm-12 col-md-6">
          {/***** Allocate 15 seconds avarage time for one timesheet *****/}
          {timesheets?.length > 0 && (
            <div className="card">
              <div className="card-body">
                <h6>
                  You have uploaded {timesheets?.length} Timesheet(s) to scan.{" "}
                </h6>
              </div>
            </div>
          )}

          {scanProcessingStatus == "DONE" && (
            <div className="card">
              <div className="card-body p-2">
                <Button
                  onClick={() => {
                    dispatch(finalManualReviewConfirmation("CONFIRMED"));
                    prepareTimesheetUrlList();
                  }}
                  color={`${
                    failedTimesheets?.length > 0 ? "warning" : "success"
                  }`}
                >
                  Confirm
                </Button>
              </div>

              {failedTimesheets?.length > 0 && (
                <div className="mx-3">
                  <Label>
                    These files are not included due to mentioned errors.Please
                    scan them seperatly or retry scaning and review before
                    confirming
                  </Label>
                  <ul>
                    {failedTimesheets?.map((file, index) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between "
                          key={index}
                        >
                          <div className="d-flex flex-column">
                            <small> File Name: {file?.file?.name} </small>
                            <small> Reason : {file?.errorMessage}</small>
                            {/* <small> Attampts : {file?.attampts}</small>   //do this later */}
                          </div>
                          <div>
                            <small>
                              <Button
                                onClick={() =>
                                  processSingleTimesheet(file?.file)
                                }
                              >
                                Retry
                              </Button>
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}

          {scanProcessingStatus == "DONE" &&
            finalManualReview == "CONFIRMED" && (
              <div className="card">
                <div className="card-body p-2">
                  <label className="mx-2">
                    Please note that only reviewed and verified timesheets will
                    be exported to the Excel sheet.
                  </label>
                  <div className="d-flex flex-row">
                    <Button
                      className="mx-1"
                      onClick={exportToExcel}
                      color={`${
                        failedTimesheets?.length > 0 ? "warning" : "success"
                      }`}
                    >
                      Export to excel sheet
                    </Button>
                    <FileDownloaderForMobile
                      files={timesheetImageList}
                      folderName={"Mobile/Direct Uploaded Reviewed Timesheets"}
                    />
                  </div>
                </div>
              </div>
            )}

          {timesheets?.sort(SortFunction).map((file, index) => {
            // {timesheets?.map((file, index) => {
            return (
              <div key={index}>
                <PendingMobileTimesheetsforVerification
                  toggleReview={toggleReview}
                  file={file}
                  review={review}
                />
                {review.includes(file.file.bookingRef) && (
                  <>
                    <CandyMobileTimeSheetBatchView
                      selectedFile={file}
                      refNum={ref3}
                      bookingNum={bookingRefNumber}
                      newRefNumber={savedRefNo}
                      systemTimeSheetData={file?.DBData}
                      timeSheetExist={file?.timeSheetExist}
                      incommingAIdata={file?.AIModalData}
                      // processTimeSheet={processTimeSheet}
                      processLoading={processLoading}
                      setShowSummary={(data) => setShowSummary(data)}
                      selectedCandidate={selectedCandidate}
                      setSelectedCandidate={(data) =>
                        setSelectedCandidate(data)
                      }
                      retry={(data) => getTimesheetFromSystem(data, file?.file)}
                      setPayrollData={(data) => {
                        setPayrollData(data);
                      }}
                      setPayrollError={(data) => {
                        setPayrollError(data);
                      }}
                      verified={toggleReview}
                    />
                  </>
                  // <TimesheetDataBatchView2></TimesheetDataBatchView2>
                )}
              </div>
            );
          })}

          {/********* For batch scan only *********/}

          {showSummary && payrollData && (
            <PayrollSummary
              summary={payrollData}
              setShowSummary={(data) => {
                setShowSummary(data);
                setPayrollData(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    timesheets: state?.mobileTimesheets?.timesheets,
    scanProcessingStatus:
      state?.mobileTimesheets?.scanProcessingStatus || "NOT_STARTED",
    failedTimesheets: state?.mobileTimesheets?.failedTimesheets || [],
    finalManualReview:
      state?.mobileTimesheets?.finalManualReviewConfirmation || "NOT_CONFIRMED",
    exportObject: state?.mobileTimesheets?.exportObject,
  };
}

export default connect(mapStateToProps, {})(MobileTimeSheetBatchScan);
