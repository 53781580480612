import React, { useState, useRef, useCallback } from "react";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../src/images/puff.svg";
import { HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Label,
  Button,
  Input,
  UncontrolledTooltip,
  Row,
  Col,
  CardBody,
  Card,
  Collapse,
} from "reactstrap";
import EmployeePayrollWidget from "../../widgets/payroll/candidate-payroll-pending-widget";
import NoSearchResults from "../errorPages/NoSearchResults";
import { useSpring } from "react-spring";
import NoConnection from "../errorPages/NoConnection";
import { FunnelIcon } from "../../assets/icons/svg";
import useFetchPayroll from "../../customHooks/useFetchPayroll";
import moment from "moment";
import dateFormatter from "../../util/common/dateFormatter";
import DateInput from "../../util/common/DateInput";
import usePayroll from "../../customHooks/usePayroll";

const TimesheetsReview = () => {
  const MexxarAPI = HOSTMexxar;

  const currentDate = new Date();
  const defaultStartDay = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).subtract(30, "days")
  );
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).add(1, "days")
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchAdvanced, setsearchAdvanced] = useState(false);
  const [resetResults, setResetResults] = useState(false);
  const [getEndDate, setGetEndDate] = useState(currentDate);
  const [getStartDate, setGetStartDate] = useState(defaultStartDay);

  function handleSearch(e) {
    setSearchQuery(e.target.value);
    setPageNumber(1);
  }

  let url = MexxarAPI + "bookings/bookingFilter/?isTimesheetUpload=false&bookingAttendedStatus=PENDING";

  const { loading, error, rows, hasMore, totalElements } = usePayroll(
    url,
    searchQuery,
    pageNumber,
    searchAdvanced,
    moment(getStartDate).format("YYYY/MM/DD"),
    moment(getEndDate).format("YYYY/MM/DD"),
    resetResults,
    true
  );

  const reset = () => {
    setPageNumber(1);
    setResetResults(!resetResults);
    setGetStartDate(defaultStartDay);
    setGetEndDate(currentDate);
  };
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}
          {totalElements.length == 1 ? " Timesheet" : " Timesheets"}{" "}
        </Label>

        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
          disabled={false}
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={(e) => handleSearch(e)}
          type="text"
          value={searchQuery}
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Timehseets..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>
      {getStartDate && (
        <small className="ml-3 mb-3">
          Showing results from{" "}
          {dateFormatter.formatDateToDDMMYYYY(getStartDate)} To{" "}
          {getEndDate !== tommorow
            ? dateFormatter.formatDateToDDMMYYYY(getEndDate)
            : dateFormatter.formatDateToDDMMYYYY(currentDate)}
        </small>
      )}
      <UncontrolledTooltip target="searchBar">
        Search Candidate Name/Id/Email/Phone No.
      </UncontrolledTooltip>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>

                <DateInput
                  id="startDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
                  onChange={(date) =>
                    setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="endDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
                  onChange={(date) =>
                    setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setPageNumber(1);
                      setsearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      <div style={{ overflowY: "auto", maxHeight: "90vh" }}>
        {Object.keys(rows).length === 0 && !loading && !error && (
          <NoSearchResults />
        )}

        {rows.map((row, index) => {
          if (rows.length === index + 1) {
            return (
              <div ref={lastElementRef} key={row.id}>
                <EmployeePayrollWidget
                  key={row.id}
                  userProfile={row}
                  refreshPage={() => setResetResults(!resetResults)}
                ></EmployeePayrollWidget>
              </div>
            );
          } else {
            return (
              <div key={row.id}>
                <EmployeePayrollWidget
                  key={row.id}
                  userProfile={row}
                  refreshPage={() => setResetResults(!resetResults)}
                ></EmployeePayrollWidget>
              </div>
            );
          }
        })}
      </div>

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

export default TimesheetsReview;
