import React, { useEffect, useState } from "react";
import "./docView.style.css";
import { split } from "lodash";
import Previews from "./Previews";
import Input from "reactstrap/lib/Input";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-dropzone-uploader/dist/styles.css";
import ImageEdit from "../../../widgets/image-edit/image-edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add_New_DOCUMENT, HOSTMexxar } from "../../../configs/api-config";
import {
  faAngleLeft,
  faAngleRight,
  faPlus,
  faMinus,
  faRedo,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const MexxarAPI = HOSTMexxar;

const SingleFileAutoSubmit = (props) => {
  const {
    doc,
    setIncomingData,
    fileUploadedOrNot,
    documentUploaded,
    expandStatus,
    deleteDoc,
    candidateServiceType,
    incomingData,
    selectedFile,
  } = props;

  const [zoom, setZoom] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [imageZoom, setImageZoom] = useState(400);
  const [pageNumber, setPageNumber] = useState(1);
  const [editModal, setEditmodal] = useState(false);
  const [editableImage, setEditableImage] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [documentPreviewURL, setDocumentPreviewURL] = useState(null);

  const [documentPreUploaded, setDocumentPreUploaded] = useState(
    doc.documentLink != null ? true : false
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let splitedArray = split(doc.documentLink, ".");
  let document_TYPE = splitedArray[1];

  const onImagePrev = (data) => {
    setEditmodal(data.show);
    setEditableImage(data.image);
  };

  const modalCallback = (data, type) => {
    setEditmodal(false);
  };

  const handleChangeStatus = (files) => {
    setIncomingData(files[0]);
  };

  const pdfNextPage = () => {
    if (numPages > pageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const pdfPreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(parseFloat(prevZoom) + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(parseFloat(prevZoom) - 0.1, 0.5));
  };

  const [rotationAngle, setRotationAngle] = useState(0);

  const rotateLeft = () => {
    setRotationAngle((angle) => angle + 90);
  };

  const rotateRight = () => {
    setRotationAngle((angle) => angle - 90);
  };

  useDidMountEffect(() => {
    fileUploadedOrNot(fileUploaded);
    return () => {};
  }, [fileUploaded]);

  useEffect(() => {
    setPageNumber(1);
    if (candidateServiceType == "PERMANENT") {
      setDocumentPreviewURL(MexxarAPI + "permanent/" + doc.documentLink);
    } else if (candidateServiceType == "TEMPORARY") {
      setDocumentPreviewURL(MexxarAPI + doc.documentLink);
    }
    return () => {};
  }, [doc]);

  return (
    <div>
      {doc.documentLink == null ? (
        <Previews
          onImagePrev={onImagePrev}
          onUpload={(data) => handleChangeStatus(data)}
          fileUploaded={(data) => setFileUploaded(data)}
          documentUploaded={documentUploaded}
          documentPreUploaded={documentPreUploaded}
          expandStatus={expandStatus}
          showUploadSection={true}
          incomingData={incomingData}
        />
      ) : document_TYPE !== "pdf" ? (
        <>
          {!expandStatus && (
            <Previews
              onImagePrev={onImagePrev}
              onUpload={(data) => handleChangeStatus(data)}
              fileUploaded={(data) => setFileUploaded(data)}
              documentUploaded={documentUploaded}
              documentPreUploaded={documentPreUploaded}
              expandStatus={expandStatus}
              showUploadSection={true}
              incomingData={incomingData}
            />
          )}

          <br />
          <div className="justify-content-center d-flex align-items-center">
            <img
              alt="Loading..."
              loading="lazy"
              src={documentPreviewURL}
              width={imageZoom * zoom}
              style={{ transform: `rotate(${rotationAngle}deg)` }}
            />
          </div>

          <div className="justify-content-center d-flex flex-column align-items-center">
            <div className="" style={{ width: "500px" }}>
              <Input
                type="range"
                min={0.5}
                max={2}
                step={0.1}
                value={zoom}
                name="range"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
              />
            </div>
            {/* <div>Zoom: {(zoom * 100).toFixed(0)}%</div> */}
            <br /> <br /> <br />
            <br />
            <div>Zoom: {(zoom * 100).toFixed(0)}%</div>
            <br />
            <div style={{ position: "absolute" }}>
              <Button
                style={{ borderRadius: "20px" }}
                color="primary"
                onClick={rotateLeft}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>{" "}
              <Button
                style={{ borderRadius: "20px" }}
                color="primary"
                onClick={handleZoomIn}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>{" "}
              <Button
                style={{ borderRadius: "20px" }}
                color="primary"
                onClick={handleZoomOut}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>{" "}
              <Button
                style={{ borderRadius: "20px" }}
                color="primary"
                onClick={rotateRight}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          {!expandStatus ? (
            <Previews
              onImagePrev={onImagePrev}
              onUpload={(data) => handleChangeStatus(data)}
              fileUploaded={(data) => setFileUploaded(data)}
              documentUploaded={documentUploaded}
              documentPreUploaded={documentPreUploaded}
              showUploadSection={true}
              incomingData={incomingData}
            />
          ) : null}

          <div className="justify-content-center d-flex flex-column align-items-center">
            <div style={{ position: "relative" }}>
              <Document
                file={documentPreviewURL}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) => {
                  console.error("Error loading PDF:", error.message);
                }}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={zoom}
                  width={expandStatus ? 1000 : 400}
                  height={expandStatus ? 1000 : 400}
                  rotate={rotationAngle}
                />
              </Document>
            </div>

            <div className="justify-content-center d-flex flex-column align-items-center">
              <div className="" style={{ width: "500px" }}>
                <Input
                  type="range"
                  min={0.5}
                  max={2}
                  step={0.1}
                  value={zoom}
                  name="range"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                />
              </div>
              <br /> <br /> <br />
              <br />
              <div>Zoom: {(zoom * 100).toFixed(0)}%</div>
              <br />
              <div style={{ position: "absolute" }}>
                <Button
                  style={{ borderRadius: "20px" }}
                  color="primary"
                  onClick={rotateLeft}
                >
                  <FontAwesomeIcon icon={faRedo} />
                </Button>{" "}
                <Button
                  style={{ borderRadius: "20px" }}
                  color="primary"
                  onClick={handleZoomIn}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>{" "}
                <Button
                  style={{ borderRadius: "20px" }}
                  color="primary"
                  onClick={handleZoomOut}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>{" "}
                <Button
                  style={{ borderRadius: "20px" }}
                  color="primary"
                  onClick={rotateRight}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div style={{ width: "5px" }}>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  onClick={pdfPreviousPage}
                  className="  btn-raised btn-wave "
                />
              </div>
              <div className="m-2">
                Page {pageNumber} of {numPages}
              </div>
              <div style={{ width: "5px" }}>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="  btn-raised btn-wave "
                  onClick={pdfNextPage}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <Modal isOpen={editModal} toggle={modalCallback}>
        <ModalHeader toggle={modalCallback}>Edit Image</ModalHeader>
        <ModalBody>
          <ImageEdit
            image={editableImage}
            editedImage={(data) => handleChangeStatus(data)}
          ></ImageEdit>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={modalCallback}>
            Save
          </Button>
          <Button color="secondary" onClick={modalCallback}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SingleFileAutoSubmit;
