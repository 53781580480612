import React, { useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import BookingSummery from "../../components/Modal/ModalBody/bookingSummery";
import ModalComponent from "../../components/Modal/modalComponent";
import ToastCommon from "../../components/toastCommon";
import { HOSTMexxar } from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import Delete from "../../components/Modal/ModalBody/delete";
import BookingCancellationReason from "../../components/Modal/ModalBody/bookingCancellation";

const CancelShiftsCardWidget = (props) => {
  const { userProfile, reset, status } = props;

  const { addToast } = useToasts();

  const [rowId, setRowId] = useState([]);
  const [dayData, setDayData] = useState(null);

  const [showDeleteRow, setShowDeleteRow] = useState({ bool: false, id: "" });
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showCancleBooking, setShowCancleBooking] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  useDidMountEffect(() => {
    axios.get(HOSTMexxar + "candidates/" + rowId).then((res) => {
      if (res.data.status == "success") {
        setSelectedCandidate(res.data.body[0]);
      }
    });
  }, [rowId]);
  const deleteRow = () => {
    axios
      .delete(HOSTMexxar + "otherShift/" + showDeleteRow.id)
      .then((res) => {
        ToastCommon(true, "delete", addToast);
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };
  return (
    <div className="">
      <div>
        <div className="card  list-row mb-2 ">
          <div className="list-item " data-id="7" data-sr-id="44">
            <div className="d-flex align-items-center mr-1 ">
              <span className="mx-1">
                <b className="badge badge-circle sm text-primary"></b>
              </span>
            </div>

            <div>
              <a data-pjax-state="" href="/employee/candidate/6">
                <span></span>
              </a>
            </div>
            <div className="flex" style={{ flex: 3 }}>
              <a className="item-author text-color" data-pjax-state="">
                {userProfile.candidateName}
              </a>
              <div>
                <small className="text-muted">{userProfile.clientName}</small>
              </div>
              <div className="item-mail text-muted h-1x d-none d-sm-block">
                <span className="mr-1">
                  <small className="text-muted">
                    {userProfile.employeeName}
                  </small>
                </span>
              </div>
            </div>

            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Shift Type</div>
              <div>{userProfile.shift?.name}</div>
              <div>
                S: {moment(userProfile?.shiftFrom).format("HH:mm A")}{" "}
              </div>{" "}
              <div>E: {moment(userProfile?.shiftTo).format("HH:mm A")}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Cancellation Date</div>
              <div>
                {moment(userProfile.cancelledDate).format("ddd DD MMM YYYY")}
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Booking Date</div>
              <div>
                {moment(userProfile.bookingDate).format("ddd DD MMM YYYY")}
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Ward</div>
              <div>{userProfile.ward?.name}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Reference No.</div>
              <div>{userProfile.referenceNo}</div>
            </div>

            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>
                <Button
                  className="mx-2 my-2 size=sm"
                  color="secondary"
                  onClick={setShowBookingModal}
                >
                  Reason
                </Button>
              </div>
            </div>

            <ModalComponent
              show={showCancleBooking}
              header="Booking Summary"
              closeModal={() => setShowCancleBooking(false)}
            >
              <BookingSummery
                reset={() => {
                  setShowCancleBooking(false);
                  reset();
                }}
                candidate={selectedCandidate}
                candidateId={Number(rowId)}
                dayData={dayData}
                heading="Booking summary"
                closeModal={() => setShowCancleBooking(false)}
                incomingPage="BOOKING_WORKSPACE"
              />{" "}
            </ModalComponent>
            <ModalComponent
              show={showBookingModal}
              header="Booking Cancelled Reason"
              closeModal={() => setShowBookingModal(false)}
            >
              <BookingCancellationReason
                userProfile={userProfile}
                closeModal={() => setShowBookingModal(false)}
              />
            </ModalComponent>

            <Delete
              show={showDeleteRow.bool}
              isConfirm={() => {
                deleteRow();
                setShowDeleteRow({ bool: false, id: "" });
              }}
              closeModal={() => setShowDeleteRow({ bool: false, id: "" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelShiftsCardWidget;
