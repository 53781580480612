import React from "react";
import { useToasts } from "react-toast-notifications";

export const ToastCommon = (show, heading, toast, messageRecived) => {
  // const { show, heading } = props;
  //console.log("showing");
  let headingName;
  let categoryColor;
  switch (heading) {
    case "chat":
      headingName = messageRecived;
      categoryColor = "success";
      break;
    case "emailChange":
      headingName = "Given Email Already in use";
      categoryColor = "warning";
      break;
    case "timesheet":
      headingName = "Timesheet attached";
      categoryColor = "error";
      break;
    case "setTime":
      headingName = "Please select start and end times";
      categoryColor = "error";
      break;
    case "timesheetUploaded":
      headingName = "Timesheet uploaded successfully";
      categoryColor = "success";
      break;
    case "timesheetProcessed":
      headingName = "Timesheet processed successfully";
      categoryColor = "success";
      break;
    case "timesheetProcessedError":
      headingName = "Please contact the admin ";
      categoryColor = "success";
      break;
    case "send":
      headingName = "Sent successfully";
      categoryColor = "success";
      break;
    case "documentDeleted":
      headingName = "Document deleted successfully";
      categoryColor = "success";
      break;
    case "eventDeleted":
      headingName = "Event deleted successfully";
      categoryColor = "success";
      break;
    case "success":
      headingName = "Added successfully";
      categoryColor = "success";
      break;
    case "documentrequired":
      headingName = "Document required";
      categoryColor = "warning";
      break;
    case "sent":
      headingName = "Sent successfully";
      categoryColor = "success";
      break;

    case "update":
      headingName = "Updated successfully";
      categoryColor = "success";
      break;
    case "dateUpdated":
      headingName = "Date Updated successfully";
      categoryColor = "success";
      break;
    case "error":
      headingName = "Something went wrong";
      categoryColor = "error";
      break;
    case "cannot":
      headingName = "Cannot delete";
      categoryColor = "error";
      break;
    case "delete":
      headingName = "Deleted successfully";
      categoryColor = "error";
      break;
    case "duplicate":
      headingName = "Already available";
      categoryColor = "warning";
      break;
    case "duplicateEmail":
      headingName = "User already existing with the same email";
      categoryColor = "warning";
      break;
    case "dateIssue":
      headingName = "Please double check Issue Date and Expire Date";
      categoryColor = "warning";
      break;
    case "dateIssueOrFile":
      headingName =
        "Please verify that the file has been uploaded and that the date is correct";
      categoryColor = "warning";
      break;

    case "basicDateIssue":
      headingName = "Please double check the Date";
      categoryColor = "warning";
      break;
    case "unableToDelete":
      headingName = "Unable to delete this event";
      categoryColor = "warning";
      break;
    case "dateCannotBePast":
      headingName = "Date is not valid";
      categoryColor = "warning";
      break;
    case "fieldCheck":
      headingName = "One or more fileds not valid";
      categoryColor = "warning";
      break;
    case "successfullyConfirmed":
      headingName = "Document confirmed successfully";
      categoryColor = "success";
      break;
    case "successfullyVerified":
      headingName = "Document verified successfully";
      categoryColor = "success";
      break;
    case "callLogStatusNotPresent":
      headingName = "Please add a call status";
      categoryColor = "success";
      break;

    case "postCode":
      headingName = "Postal code is not valid";
      categoryColor = "warning";
      break;
    case "resetPassword":
      headingName = "Password reset successfully";
      categoryColor = "success";
      break;
    case "candidateServiceTypeUnidentify":
      headingName = "Cannot identify the candidate's service type";
      categoryColor = "warning";
      break;
    case "addANote":
      headingName = "Please add a note";
      categoryColor = "warning";
      break;

    case "docNotApproved":
      headingName = "One or more documents is not approved";
      categoryColor = "warning";
      break;
    case "workflowUpdated":
      headingName = "Workflow updated successfully";
      categoryColor = "success";
      break;
    case "coppied":
      headingName = "Coppied successfully";
      categoryColor = "success";
      break;
  }

  if (show) {
    return toast(headingName, {
      appearance: categoryColor,
      autoDismiss: true,
    });
  } else {
  }
};
export default ToastCommon;
