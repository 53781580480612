import React, { useState, useEffect, useRef } from "react";
import {
  Label,
  Form,
  FormGroup,
  Col,
  Button,
  Spinner,
  Input,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import store from "../../redux/store";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  forEach,
  findIndex,
  filter,
  isEmpty,
  isNull,
  find,
  debounce,
} from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import useCandidateList from "../../customHooks/useCandidateList";
import {
  REGISTER_CANDIDATE,
  HOSTMexxar,
  ClientLable,
} from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import {
  clientsGetAction,
  gradesGetAction,
} from "../../redux/actions/initialDataGetAction";
import { saveFiledDataAction } from "../../redux/actions/saveFiledDataAction";
import {
  deleteOneTimesheetsLocally,
  updateTimesheetReviewedDataCandy,
} from "../../redux/actions/timesheetBatchScanAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import calculateShiftRates from "./splitRates";
import useClientsFetch from "../../customHooks/useClientsFetch";
import AddRejectNoteModal from "../../components/AddANote/AddRejectNote";
import { rejectTimesheet } from "../../util/common/rejectTimeSheet";

function CandyTimesheetDataBatchViewAngi(props) {
  const {
    // processTimeSheet,
    processLoading,
    selectedCandidate,
    setSelectedCandidate,
    incommingAIdata,
    systemTimeSheetData,
    timeSheetExist,
    retry,
    retry2,
    selectedFile,
    setPayrollData,
    setShowSummary,
    setPayrollError,
    user,
    timesheet,
    verified,
    reduxGrades,
    reduxClients,
  } = props;

  const MexxarCandidateApi = REGISTER_CANDIDATE;
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [candidates, setCandidates] = useState([]);

  const departmentIndexRef = useRef(null); // Create a ref for the input
  const specialityIndexRef = useRef(null); // Create a ref for the input

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const [departmentError, setDepartmentError] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(0);
  const [specialities, setSpecialities] = useState([{ id: 0, name: "Select" }]);

  const [currentGrade, setCurrentGrade] = useState(1);
  const [grades, setGrades] = useState(null);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [departments, setDepartments] = useState([{ id: 0, name: "Select" }]);

  const [refNo, setRefNo] = useState(null);
  const [fieldData, setFieldData] = useState(null);

  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([
    { id: 0, name: "Select", value: "-1", label: "Select", meritClientId: "" },
  ]);

  const [clientCantFind, setClientCantFind] = useState(false);
  const [departmentCantFind, setDepartmentCantFind] = useState(false);
  const [specialityCantFind, setSpecialityCantFind] = useState(false);

  const [pdfError, setAiError] = useState(false);
  const [aiPending, setAiPending] = useState(false);
  const [defaultRates404, setDefaultRates404] = useState(false);

  // const [pageNumberClients, setPageNumberClients] = useState(1);
  // const [queryClients, setQueryClients] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const { loadingClients, errorClients, rowsClients, hasMoreClients } =
  //   useClientsFetch(
  //     HOSTMexxar + "clients/filter?",
  //     queryClients,
  //     pageNumberClients
  //   );

  //prefixed rate types to set ids for each section
  const [rateTypes, setRateTypes] = useState([
    { type: "Rate Week Days", key: 0, shortForm: "WEEK" },
    { type: "Rate Saturday", key: 1, shortForm: "SAT" },
    ,
    { type: "Rate Sunday", key: 2, shortForm: "SUN" },
    { type: "Rate Bank Holiday", key: 3, shortForm: "BH" },
  ]);

  const { addToast } = useToasts();

  const handleInputChange = debounce((input) => {
    setQuery(input); // Update query state for the hook
    setPageNumber(1); // Reset pagination on new input
  }, 300);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // console.log("incommingAIdata...........", incommingAIdata);
  //----------prepare candidates to popultate in dorp down select---------//
  const prepareCandidates = () => {
    let listOfCandidates = rows.map((row) => ({
      value: row?.id,
      label: `${row?.firstName} ${row?.lastName}`,
      id: row?.id,
      candidate: row,
    }));

    if (query) {
      setCandidates(listOfCandidates);

      // Only set `selectedCandidate` if there's no existing selection or the current selection is invalid
      if (
        !selectedCandidate ||
        !listOfCandidates.some((c) => c.id === selectedCandidate.id)
      ) {
        setSelectedCandidate(listOfCandidates[0] || null);
      }
    } else {
      // When query is empty, clear only candidates, but retain the current selection
      setCandidates([]);
    }
  };

  const getClientDepartments = (data) => {
    if (data) {
      axios
        .get(HOSTMexxar + "client-wards/clients/" + data)
        .then((res) => {
          let tempDep = [];
          forEach(res.data.body, function (department) {
            tempDep.push({
              id: department?.id, //from client-wards API- clientWardId is id.
              name: department?.wardName,
              wardId: department?.wardId,
            });
          });
          setDepartments(tempDep);
        })
        .catch((err) => {
          setDepartmentError(err);
          // console.log("err", err);
          // toastCommon(true, "error");
        });
    }
  };

  //--------------[process flow no--00]--------------//

  const prepareFiledData = () => {
    const cleanUpName = (name) => {
      // Check if name is null, undefined, or not a string
      if (!name || typeof name !== "string") {
        return ""; // Return an empty string if invalid
      }
      return name.replace(/\s+/g, " ").trim();
    };

    let tempFields;

    //when AI scanned ref no is not matching with excisting db bookings data

    if (!timeSheetExist) {
      console.log(
        "this is the first run prorcesss------1-------->",
        incommingAIdata
      );

      if (incommingAIdata) {
        tempFields = {
          referenceNo: incommingAIdata.Ref.replace(/\s/g, ""),
          date: incommingAIdata.Date,
          candidate: {
            id: incommingAIdata.CandidateId,
            value: cleanUpName(incommingAIdata.CandidateName),
            label: cleanUpName(incommingAIdata.CandidateName),
          },
          candidateId: incommingAIdata.CandidateId,
          startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          startTime: moment(incommingAIdata.Start, "HH:mm").format("HH:mm"),
          endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          endTime: moment(incommingAIdata.End, "HH:mm").format("HH:mm"),
          client: {
            // id: incommingAIdata.clientId,
            name: incommingAIdata.HospitalName,
          },
          department: {
            // id: incommingAIdata.wardId,
            name: incommingAIdata.Ward,
          },
          speciality: incommingAIdata.Speciality,
          upLift: 0,
          bonus: 0,
          minsTakenForBreaks: incommingAIdata.Breakmin,
          totalHours: incommingAIdata.Totalhours,
        };
      }
    } else {
      console.log(
        "this is the first run prorcesss------2-------->",
        systemTimeSheetData
      );

      //when AI scanned refno. is matching with excisting system bookings

      if (systemTimeSheetData) {
        tempFields = {
          referenceNo: systemTimeSheetData.referenceNo.replace(/\s/g, ""),
          date: systemTimeSheetData.bookingDate,
          candidate: {
            id: systemTimeSheetData.candidatesId,
            value: cleanUpName(systemTimeSheetData.candidateName),
            label: cleanUpName(systemTimeSheetData.candidateName),
          },
          candidateId: systemTimeSheetData?.candidatesId,
          startDate: moment(systemTimeSheetData?.shiftFrom).format(
            "YYYY-MM-DD"
          ),
          startTime: moment(systemTimeSheetData?.shiftFrom).format("HH:mm"),
          endDate: moment(systemTimeSheetData?.shiftFrom).format("YYYY-MM-DD"),
          endTime: moment(systemTimeSheetData?.shiftTo).format("HH:mm"),
          client: {
            id: systemTimeSheetData.clientId,
            name: systemTimeSheetData.clientName,
          },
          department: {
            id: systemTimeSheetData.ward?.id,
            name: systemTimeSheetData.ward?.name,
          },
          speciality: systemTimeSheetData.speciality,
          upLift: systemTimeSheetData.upLift,
          bonus: systemTimeSheetData.bonus,
        };
      }
    }

    store.dispatch(saveFiledDataAction(tempFields));
    setFieldData(tempFields);
  };

  const handleCandidateChange = (data) => {
    //  setQuery(data.label);
    setSelectedCandidate(data);
  };

  //*--------------prepare end date ------------------*//
  //*--------------if the end time is after 12 midnight add one date to the start date ------------------*//

  function prepEndDate(data) {
    let sTime = data.startTime.split(":")[0];
    let eTime = data.endTime.split(":")[0];

    let endDate;

    if (sTime < eTime) {
      endDate = moment(data.startDate).format("DD/MM/YYYY");
    } else {
      endDate = moment(data.startDate).add(1, "days").format("DD/MM/YYYY");
    }
    return endDate;
  }

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }

    return { shortForm: shortForm, type: type, key: key };
  };

  //Get Candidate/Client and Client's Default Rates Function
  const getRates = async (
    clientId,
    currentDepartment,
    type,
    candidateId = "",
    rateGradesId
  ) => {
    setDefaultRates404(false);
    let tempUpdatingArray = [];
    let tempArray = [];

    try {
      let ratesUrl = "";

      if (type === "CLIENT") {
        ratesUrl = `${HOSTMexxar}client-rate-grade/clientRateGrades`;
      } else if (type === "CANDIDATE") {
        ratesUrl = `${HOSTMexxar}candidate-rate-grade/candidateRateGrades`;
      }

      const res = await axios({
        method: "GET",
        url: ratesUrl,
        params: {
          page: 1,
          offset: 20,
          clientId: clientId,
          clientWardId: currentDepartment,
          rateGradesId: rateGradesId,
          ...(type === "CANDIDATE" && { candidateId: candidateId }), // Add candidateId only for 'Candidate'
        },
      });

      let response = res.data.body.content;
      if (response.length === 0) {
        return [];
      }

      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        console.log(`Client ID: ${clientId}`);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0]?.id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0]?.id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0]?.id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0]?.id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0]?.startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0]?.endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0]?.rate,
          nChargeRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0]?.chargeRate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,
          dChargeRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].chargeRate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
          bChargeRate:
            RateModeSpecial[0] === undefined
              ? ""
              : RateModeSpecial[0].chargeRate,
        });
        console.error(" -------rates----->tempArray", tempUpdatingArray);

        // setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      });

      return tempUpdatingArray;
    } catch (error) {
      console.error("Error fetching client rates:", error);

      return [];
    }
  };

  //*--------------verify document-------------------*//
  //--------------[process flow no--01]--------------//

  const onSubmit = async (data) => {
    try {
      setOnSubmitLoading(true);
      console.log("On Submitted data: 1050", data);

      // Rates retrieval logic
      const clientId = selectedClient?.id;
      const departmentId = departments[parseInt(data?.departmentIndex)]?.id;
      const candidateId = candidates[0]?.candidate?.id;

      let rates = [];

      console.log("On Submitted data:", departments);

      // 1. Get Candidate Rate
      rates = await getRates(
        clientId,
        departmentId,
        "CANDIDATE",
        candidateId,
        currentGrade
      );

      const date = new Date(data?.startDate);
      const dayOfWeek = date.getDay();
      let dayType;

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        dayType = typeFinder("WEEK");
      } else if (dayOfWeek === 0) {
        dayType = typeFinder("SUN");
      } else if (dayOfWeek === 6) {
        dayType = typeFinder("SAT");
      } else {
        dayType = typeFinder("BH"); // Bank Holidays
      }

      console.log("Day Type:", dayType);

      const formattedstartDateTime = moment(
        `${data?.startDate} ${data.startTime}`,
        "YYYY-MM-DD HH:mm"
      ).format("YYYY-MM-DDTHH:mm:ss");
      const formattedendDateTime = moment(
        `${data?.startDate} ${data.endTime}`,
        "YYYY-MM-DD HH:mm"
      ).format("YYYY-MM-DDTHH:mm:ss");

      // TEST DATA
      // Function to fix endDate if it crosses midnight
      function fixShiftEndDate(shift) {
        let start = new Date(shift.startDate);
        let end = new Date(shift.endDate);

        // If the end time is earlier than the start time, it means it crosses midnight
        if (end <= start) {
          end.setDate(start.getDate() + 1); // Add one day to the end date
          shift.endDate = end.toISOString(); // Update the shift object with the corrected date
        }
      }

      let nStartHour = 20; //by default keep this as 06 and 20
      let dStartHour = 6;

      // Function to map Day start hour and Night Start Hour
      const weekDaysRate = rates?.find(
        (item) => item.type === "Rate Week Days"
      );
      if (weekDaysRate) {
        nStartHour = weekDaysRate.nStart.split(":")[0]; // Get the hour from nStart
        dStartHour = weekDaysRate.dStart.split(":")[0]; // Get the hour from dStart
      }
      // Function to map pay and charge rates

      let chargeRates = rates;
      let payRates = rates;

      // Find the "Rate Week Days" entry

      function mapRates() {
        const shift = {
          startDate: formattedstartDateTime, // Friday 19:00
          endDate: formattedendDateTime, // Saturday 02:30
          chargeRates: {},
          payRates: {},
          bankHolidays: [], // Example holiday
          startHourDay: weekDaysRate ? dStartHour : 6,
          startHourNight: weekDaysRate ? nStartHour : 20,
        };

        // Map charge rates
        shift.chargeRates = {
          weekday: {
            day: chargeRates[0]?.dChargeRate, // Charge rate for Weekday Day
            night: chargeRates[0]?.nChargeRate, // Charge rate for Weekday Night
          },
          saturday: {
            day: chargeRates[1]?.dChargeRate, // Charge rate for Saturday Day
            night: chargeRates[1]?.nChargeRate, // Charge rate for Saturday Night
          },
          sunday: {
            day: chargeRates[2]?.dChargeRate, // Charge rate for Sunday Day
            night: chargeRates[2]?.nChargeRate, // Charge rate for Sunday Night
          },
          bankHoliday: {
            day: chargeRates[3]?.dChargeRate, // Charge rate for Bankholiday Day
            night: chargeRates[3]?.nChargeRate, // Charge rate for Bankholiday Night
          },
        };

        // Map pay rates
        shift.payRates = {
          weekday: {
            day: payRates[0]?.dRate, // Pay rate for Weekday Day
            night: payRates[0]?.nRate, // Pay rate for Weekday Night
          },
          saturday: {
            day: payRates[1]?.dRate, // Pay rate for Saturday Day
            night: payRates[1]?.nRate, // Pay rate for Saturday Night
          },
          sunday: {
            day: payRates[2]?.dRate, // Pay rate for Sunday Day
            night: payRates[2]?.nRate, // Pay rate for Sunday Night
          },
          bankHoliday: {
            day: payRates[3]?.dRate, // Pay rate for Bankholiday Day
            night: payRates[3]?.nRate, // Pay rate for Bankholiday Night
          },
        };

        return shift;
      }

      // Call the function to get the mapped shift object
      const shiftObject = mapRates();

      fixShiftEndDate(shiftObject);

      let ratesEntries = [];

      ratesEntries = calculateShiftRates(shiftObject);
      console.log("edge pay rates:", rates);
      console.log("edge shiftObject", shiftObject);
      console.log("edge rates seperated function", ratesEntries);

      // Prepare an array to store all export objects
      let exportObjects = [];

      exportObjects = ratesEntries.map((rateEntry) => {
        return {
          PaymentPeriod: "",
          ClientID: selectedClient?.meritClientId,
          MeritID: candidates?.[0]?.candidate?.meritId ?? "",
          ExternalID: candidates?.[0]?.id,
          MeritPlacementID:
            candidates?.[0]?.candidate?.meritPlacementIds
              ?.map((item) => item)
              .join(", ") || "",
          ExternalPlacementID: "",
          WorkDescription: "Temp Staff",
          PayDescription: rateEntry.PayDescription,
          Quantity: rateEntry.Quantity, // Calculated hours for each rate
          PayRate: rateEntry.PayRate,
          ChargeRate: rateEntry.ChargeRate,
          Reference: data?.refNo,
          Ref2: "",
          Startdate: moment(shiftObject?.startDate).format("YYYY-MM-DD"),
          Enddate: moment(shiftObject?.endDate).format("YYYY-MM-DD"),
          Comments: "",
          EndUserClient: "",
          ComparatorHolidayDays: "",
          PaymentCurrencyCode: "GBP",
          InvoiceCurrencyCode: "",
          VatAdjust: "",
          Consultant: user,
          SplitRate: ratesEntries?.length > 1 || false, // Add splitRate key
        };
      });
      console.log(" preview data------------->", exportObjects);

      // Dispatch all rate entries together in a single action
      dispatch(
        updateTimesheetReviewedDataCandy(
          selectedFile.DBData.referenceNo, // Reference number remains the same
          exportObjects, // Array of export objects for each rate entry (day/night)
          new Date() // Current date for timesheetReviewDateTime
        )
      );

      let timesheet = {
        locumName: candidates[0]?.label,
        locumId: candidates[0]?.id,
        speciality: specialities[data?.specialityIndex]?.id,
        band: candidates[0]?.candidate?.candidateBand?.id,
        hospitalName: selectedClient?.name,
        dateOfShift: data?.startDate,
        ward: departments[data?.departmentIndex]?.id,
        bookingRef: data?.refNo,
        startTime: data?.startTime,
        endTime: data?.endTime,
        minsTakenForBreaks: data?.minsTakenForBreaks,
        totalHours: data?.totalHours,
        locumDate: data?.startDate,
        authoriseName: "",
        authorisePosition: "",
        authoriseDate: data?.startDate,
        bookingId: systemTimeSheetData?.id,
        hospitalReviewStatus: 0,
        paymentStatus: 0,
        reviewStatus: 4,
      };

      let form = new FormData();
      form.append("file", selectedFile.file);
      let dateTimeNow = new Date();
      axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
        if (res.data.status === "success" && res.data.message === "created") {
          updateBookingIfNotMatching(data, res, form);
          dispatch(
            updateTimesheetReviewedDataCandy(
              selectedFile.DBData.referenceNo,
              ratesEntries,
              dateTimeNow
            )
          );
          verified(selectedFile);
        } else if (
          res.data.status === "success" &&
          res.data.message === "updated"
        ) {
          ToastCommon(true, "update", addToast);
          updateBookingIfNotMatching(data, res, form);
          dispatch(
            updateTimesheetReviewedDataCandy(
              selectedFile.DBData.referenceNo,
              ratesEntries,
              dateTimeNow
            )
          );
          verified(selectedFile);
        } else if (res.data.status === "failed") {
          ToastCommon(true, "error", addToast);
        }
      });
    } catch (error) {
      setOnSubmitLoading(false);
      console.error("Error in onSubmit:", error);
    }
  };

  //*--------------Update booking details in the system if even one data is miss matching with verifying data------------------*//
  //--------------[process flow no--02]--------------//

  const updateBookingIfNotMatching = (data, response, form) => {
    let shiftSTime = moment(data.startDate).add(data.startTime).toDate();
    let shiftETime = moment(data.startDate).add(data.endTime).toDate();

    let arrangData = {
      id: systemTimeSheetData?.id,
      bookingDate: moment(data.startDate).format("YYYY-MM-DD"),
      referenceNo: data.refNo,
      clientsId: Number(selectedClient?.id),
      //  employeeId: auth.item.id,
      // specialityId: Number(candidateSpecialities[0].id),
      specialityId: Number(specialities[data.specialityIndex]?.id),
      wardId: Number(departments[data.departmentIndex].wardId), //<---------change this later to  Number(departments[data.departmentIndex].id) which is the client's wardId
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
    };

    // if any field is not matching with system booking details, update it

    console.log("data.refNo", data.refNo);
    console.log(
      "systemTimeSheetData.referenceNo",
      systemTimeSheetData.referenceNo
    );
    if (
      systemTimeSheetData.referenceNo != data.refNo ||
      systemTimeSheetData.candidatesId != candidates[0].id ||
      moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
        moment(data.startDate).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
        moment(data.startTime).format("HH:mm") ||
      moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
        moment(data.endTime).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
        moment(prepEndDate(data)).format("HH:mm") ||
      systemTimeSheetData.clientId != selectedClient?.id ||
      systemTimeSheetData.ward?.id != departments[data.departmentIndex]?.id ||
      systemTimeSheetData.speciality?.id !=
        specialities[data.specialityIndex]?.id
    ) {
      axios
        .put(HOSTMexxar + "bookings", arrangData)
        .then((res) => {
          // console.log("angi bookings uplaod---->");
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            let id = response.data.body[0]?.id;
            uploadTimesheet(data, id, form);
          } else if (res.data.status == "failed") {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {});
    }
    // else if fields  are matching with system booking details, upload the Timesheet
    else {
      let id = response.data.body[0]?.id;
      uploadTimesheet(data, id, form);
    }
  };

  //*--------------upload timesheet------------------*//

  const createTimesheet = (data) => {
    return axios
      .post(HOSTMexxar + "timesheets/", data)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);

          // Return the id and data for chaining
          return res?.data;
        } else if (res.data.status === "failed") {
          ToastCommon(true, "error", addToast);
          throw new Error("Upload failed");
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
        throw err; // Propagate the error
      });
  };

  const uploadTimesheet = (data, id, form) => {
    return axios
      .put(HOSTMexxar + "timesheets/" + id + "/upload-timesheetDocument", form)
      .then((res) => {
        if (res.data.status === "success" && res.data.message === "fetched") {
          ToastCommon(true, "timesheetUploaded", addToast);

          // Return the id and data for chaining
          return { res: data };
        } else if (res.data.status === "failed") {
          ToastCommon(true, "error", addToast);
          throw new Error("Upload failed");
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
        throw err; // Propagate the error
      });
  };
  //*--------------process timesheet for payroll calculations------------------*//
  //--------------[process flow no--04]--------------//

  const processTimesheetData = (item) => {
    setAiPending(true);

    let tempData = [
      {
        bookingReference: item.refNo,
        startTime: item.startTime + ":00",
        endTime: item.endTime + ":00",
        startDate: moment(item.startDate).format("DD/MM/YYYY"),
        endDate: prepEndDate(item),
      },
    ];

    axios
      .post(HOSTMexxar + "workspace/payroll", tempData)
      .then((res) => {
        setAiPending(false);
        if (res.data.status == "success") {
          ToastCommon(true, "timesheetProcessed", addToast);
          setPayrollData(res.data.body);
          setShowSummary(true);
        } else if (res.data.status == "failed") {
          setPayrollError(res.data.status);
          ToastCommon(true, "timesheetProcessedError", addToast);
        }
      })
      .catch((err) => {
        setAiError(true);
      });
  };

  //two types of data coming
  //a.AI data(incommingAIdata)
  //b.DB Data(systemTimeSheetData)
  //*********** Process ***********/
  //1.When systemTimeSheetData comes prepare them to populate in the form
  //     a.Check if timehsheet ref no is can be found in the system -using timeSheetExist flag to determine this
  //     b.If its matching then populate system data directly to the form (fieldData)
  //     c.else populate incommingAIdata to the form (fieldData)
  //         c.0. Since AI Scanned booking reference not available follow below process to find it from the system
  //         c.1. Type the correct ref id in the reference filed and click "Retry"
  //                if : available then populate new found data in the fieldData-  **call prepareFiledData()
  //                   click verufy with other data.This will update the current booking details in the DB
  //                else : user cannot change data.Since no booking is made with given timehseet ref no(No booking Id to update the data in the DB)
  //     d.

  useEffect(() => {
    if (!reduxGrades) {
      store.dispatch(gradesGetAction());
    } else {
      setGrades(reduxGrades);
      //

      if (systemTimeSheetData) {
        setCurrentGrade(systemTimeSheetData?.rateGrades?.id);
      }
    }
  }, [reduxGrades]);

  useEffect(() => {
    prepareFiledData();
    return () => {};
  }, [timeSheetExist, systemTimeSheetData]);

  useDidMountEffect(() => {
    prepareCandidates();
    return () => {
      store.dispatch(saveFiledDataAction(null));
    };
  }, [rows]);

  useDidMountEffect(() => {
    if (fieldData) {
      // console.log("angi process 3");
      setQuery(fieldData.candidate.value);
    }
  }, [fieldData]);

  useDidMountEffect(() => {
    if (fieldData && clients) {
      let temp = filter(clients, (item) => {
        return item.name == fieldData.client.name;
      });

      if (!temp) {
        // console.log("angi process 5.1");
        setClientCantFind(true);
      } else {
        setClientCantFind(false);
        const mappedSelectedClient = temp?.map((client, index) => ({
          id: client.id,
          value: client.id,
          label: client.name,
          name: client.name,
          meritClientId: client.meritClientId,
        }));

        setSelectedClient(mappedSelectedClient?.[0]);
      }
    }
  }, [clients]);

  useDidMountEffect(() => {
    if (fieldData && specialities) {
      // console.log("angi process 6 ");
      let index = findIndex(specialities, (item) => {
        return item.name == fieldData.speciality?.name;
      });

      if (index === -1) {
        //  console.log("angi process 6.1");
        setSpecialityCantFind(true);
      } else {
        //  console.log("angi process 6.2");
        setSpecialityCantFind(false);
        setSelectedSpeciality(index);
      }
    }
  }, [specialities, fieldData]);

  useDidMountEffect(() => {
    // console.log("angi process 7");
    if (!reduxClients) {
      store.dispatch(clientsGetAction());
    }
  }, [systemTimeSheetData]);

  // const handleInputChangeClients = (inputValue) => {
  //   setQueryClients(inputValue);
  // };

  // const handleMenuScrollToBottomClients = () => {
  //   if (hasMoreClients && !loadingClients) {
  //     setPageNumber((prevPageNumber) => prevPageNumber + 1);
  //   }
  // };
  // useEffect(() => {
  //   // setClients([]);
  //   setPageNumber(1);
  //   console.log("angi reducx clients-----?>",reduxClients)
  //   setClients(reduxClients);
  // }, []);

  useEffect(() => {
    if (reduxClients?.length) {
      const mappedClients = reduxClients.map((client, index) => ({
        id: client.id,
        value: index,
        label: client.name,
        name: client.name,
        meritClientId: client.meritClientId,
      }));
      setClients(mappedClients);
    } else {
      store.dispatch(clientsGetAction());
    }
  }, [reduxClients]);

  useDidMountEffect(() => {
    if (selectedCandidate) {
      //  console.log("angi process 4.1", selectedCandidate?.candidate?.preferredClients);
      // setClients(selectedCandidate?.candidate?.preferredClients || []);
      setSpecialities(
        selectedCandidate?.candidate?.candidateSpecialities || []
      );
      // getClientDepartments(
      //   selectedCandidate?.candidate?.preferredClients[0]?.id    //dont use this
      // );
    } else {
      //  console.log("angi process 4.2");
      // setClients([{ id: 0, name: "Select" }]);
      setSpecialities([{ id: 0, name: "Select" }]);
    }
  }, [selectedCandidate]);

  useDidMountEffect(() => {
    {
      clients && selectedClient && getClientDepartments(selectedClient?.id);
    }
  }, [selectedClient]);

  useDidMountEffect(() => {
    if (fieldData && departments) {
      // console.log("angi process 10 ");
      let index = findIndex(departments, (item) => {
        return item.name == fieldData.department.name;
      });

      if (index === -1) {
        // console.log("angi process 10.1");
        setDepartmentCantFind(true);
      } else {
        // console.log("angi process 10.2");
        setDepartmentCantFind(false);
        setSelectedDepartment(index);
      }
    }
  }, [departments]);

  useDidMountEffect(() => {
    // console.log("angi process 11 clearing every state ");
    setFieldData(null);
    setQuery("");
    setSelectedDepartment(0);
    setDepartments([{ id: 0, name: "Select" }]);
    reset();
  }, [selectedFile.file]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#121212",
    }),
  };

  const handleSaveRejectNote = (noteReject) => {
    let id = selectedFile.DBData.id;
    let form = new FormData();
    form.append("file", selectedFile.file);

    //since we are rejecting the timesheet we are gathering data from both system and Ai and populate.
    //Also we first need to create the timesheet in order to upload it. Hence two requests are going out

    let timesheet = {
      locumName: candidates[0]?.label,
      locumId: candidates[0]?.id,
      speciality: specialities[specialityIndexRef.current.props.value]?.id,
      band: candidates[0]?.candidate?.candidateBand?.id,
      hospitalName: selectedClient?.name,
      dateOfShift: fieldData && fieldData.startDate,
      ward: departments[departmentIndexRef.current.props.value]?.id, //do somthing here to retrive the correct data
      bookingRef: fieldData && fieldData.referenceNo,
      startTime: fieldData && fieldData.startTime,
      endTime: fieldData && fieldData.endTime,
      minsTakenForBreaks: incommingAIdata && incommingAIdata.Breakmin,
      totalHours: selectedFile?.manuallyReviewed
        ? selectedFile?.reviewedData?.Quantity
        : incommingAIdata && incommingAIdata.Totalhours,
      locumDate: fieldData && fieldData.startDate,
      authoriseName: "",
      authorisePosition: "",
      authoriseDate: fieldData && fieldData.startDate,
      bookingId: systemTimeSheetData?.id,
      hospitalReviewStatus: 0,
      paymentStatus: 0,
      reviewStatus: 4,
    };

    createTimesheet(timesheet).then((data) => {
      if (data.status === "success") {
        let selectedRow = {
          id: data?.body?.[0].id,
          booking: {
            id: selectedFile.DBData.id,
            candidateId: Number(selectedFile.DBData.candidatesId),
          },
        };

        uploadTimesheet(data?.body?.[0], data?.body?.[0].id, form)
          .then(({ res }) => {
            if (selectedRow) {
              rejectTimesheet(
                selectedRow,
                noteReject,
                "DECLINE",
                addToast
              ).then((result) => {
                if (result.success) {
                  reset();
                  dispatch(
                    deleteOneTimesheetsLocally(selectedFile.DBData.referenceNo)
                  );
                  ToastCommon(true, "success", addToast); // Show success toast
                } else {
                  ToastCommon(true, "error", addToast); // Show error toast
                }
              });

              toggleModal();
            }
          })
          .catch((err) => {
            console.error("Error during upload:", err);
          });
      }
    });
  };

  const handleReject = () => {
    toggleModal();
  };

  return (
    <div className="card " style={{ width: "100%" }}>
      <div className="card-body bg-light">
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <FormGroup
            row
            className="d-flex justify-content-between align-items-center"
          >
            <Label className="ml-3">Process Timesheet</Label>
            <small>
              Please note that the data below is populated against the candidate
              identified from the candidate field.
            </small>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Col md={`${timeSheetExist ? 12 : 10}`}>
              <Label>Ref No</Label>
              <Input
                type="text"
                defaultValue={fieldData && fieldData.referenceNo}
                placeholder="Ref No"
                id="refNo"
                name="refNo"
                onChange={(e) => setRefNo(e.target.value)}
                invalid={
                  !timeSheetExist ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.referenceNo.replace(/\s/g, "") !=
                      incommingAIdata.Ref.replace(/\s/g, ""))
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            {!timeSheetExist && (
              <Col md={2} className="d-flex align-items-end ">
                <Button id="retry" color="success" onClick={() => retry(refNo)}>
                  Retry
                </Button>

                <UncontrolledTooltip target="retry">
                  Retry to find reference id
                </UncontrolledTooltip>
              </Col>
            )}
          </FormGroup>

          <FormGroup row>
            <Col md={12}>
              <Label>Candidate</Label>
              <Select
                id="candidateId"
                name="candidateId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedCandidate}
                onChange={(data) => {
                  handleCandidateChange(data);
                }}
                isLoading={loading}
                options={candidates}
                placeholder="Search Candidates ..."
                onInputChange={(data) => {
                  handleInputChange(data);
                }}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                styles={colourStyles}
              />
            </Col>
            <Col md={6}></Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>Start Date</Label>
              <Input
                type="Date"
                defaultValue={fieldData && fieldData.startDate}
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                placeholder="Start Time"
                id="startDate"
                name="startDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.Date).format("YYYY-MM-DD")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>Start Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                    moment(incommingAIdata.Start, "HH:mm").format("HH:mm")
                }
                defaultValue={fieldData && fieldData.startTime}
                id="startTime"
                name="startTime"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                  moment(incommingAIdata.Start, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Start, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>{" "}
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>End Date</Label>
              <Input
                type="Date"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                defaultValue={fieldData && fieldData.endDate}
                id="endDate"
                name="endDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>End Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                    moment(incommingAIdata.End, "HH:mm").format("HH:mm")
                }
                placeholder="End Time"
                id="endTime"
                name="endTime"
                defaultValue={fieldData && fieldData.endTime}
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                  moment(incommingAIdata.End, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.End, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>{ClientLable}</Label>
              {clients ? (
                <Select
                  value={selectedClient}
                  // isMulti
                  name="client"
                  id="client"
                  // invalid={!!errorClients}
                  styles={colourStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => {
                    setSelectedClient(data);
                  }}
                  options={clients}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                  // isLoading={loadingClients}
                  // onInputChange={handleInputChangeClients}
                  // onMenuScrollToBottom={handleMenuScrollToBottomClients}
                  noOptionsMessage={() =>
                    loading ? "Loading..." : "No more clients"
                  }
                />
              ) : null}

              {clientCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.clientName?.toLowerCase() !=
                    incommingAIdata.HospitalName?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.HospitalName}
                      </li>
                    </ul>
                  ))}
            </Col>
            <Col md={6}>
              <Label>Department</Label>
              <Input
                type="select"
                id="departmentIndex"
                name="departmentIndex"
                ref={departmentIndexRef} // Attach the ref here
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                invalid={
                  departmentCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.ward?.name?.toLowerCase() !=
                      incommingAIdata.Ward?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {departments.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {departmentCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.ward?.name?.toLowerCase() !=
                    incommingAIdata.Ward?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Ward}
                      </li>
                    </ul>
                  ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <Label>Specialities</Label>
              <Input
                type="select"
                id="specialityIndex"
                name="specialityIndex"
                value={selectedSpeciality}
                ref={specialityIndexRef}
                onChange={(e) => setSelectedSpeciality(e.target.value)}
                invalid={
                  specialityCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                      incommingAIdata?.Speciality?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {specialities.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {specialityCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                    incommingAIdata?.Speciality?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Speciality}
                      </li>
                    </ul>
                  ))}
            </Col>
            <Col md={6}>
              <Label>Grade</Label>
              <Input
                type="select"
                name="grade"
                value={currentGrade && currentGrade}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                onChange={(selected) => setCurrentGrade(selected.target.value)}
              >
                <option value="-1">Select</option>
                {grades &&
                  grades?.map((grade, key) => (
                    <option value={grade.id} key={key}>
                      {grade.name} - {grade.escalation && "Escalation"}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>Break Mins.</Label>
              <Input
                type="text"
                placeholder="Break Mins."
                id="minsTakenForBreaks"
                name="minsTakenForBreaks"
                defaultValue={incommingAIdata && incommingAIdata.Breakmin}
                invalid={errors.minsTakenForBreaks}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            <Col sm={6}>
              <Label>Total Hours</Label>
              <Input
                type="text"
                placeholder="Total Hours"
                id="totalHours"
                name="totalHours"
                defaultValue={
                  selectedFile?.manuallyReviewed
                    ? selectedFile?.reviewedData?.Quantity
                    : incommingAIdata && incommingAIdata.Totalhours
                }
                invalid={
                  errors.totalHours ||
                  (selectedFile?.reviewedData?.Quantity > 24 &&
                    incommingAIdata.Totalhours > 24)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
              {selectedFile?.reviewedData?.Quantity > 24 &&
                incommingAIdata.Totalhours > 24 && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      Total hours are exceeding 24 hours
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Alert color="info">
                <strong className="text-danger">*</strong> The text in{" "}
                <strong className="text-danger">red</strong> represent
                recognized hand written text{" "}
              </Alert>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mr-5 ">
              {isEmpty(systemTimeSheetData) && (
                <Alert color="danger" className="mx-2">
                  We couldn't find a booking with the provided reference number.
                  Please ensure that you have the correct reference number for
                  the relevant booking made for above date and update it if it
                  doesn't match with the timesheet.
                </Alert>
              )}
              {isNull(systemTimeSheetData?.referenceNo) && (
                <Alert color="danger" className="mx-2">
                  We couldn't find the booking reference number. Please enter
                  the relevant reference number above.{" "}
                </Alert>
              )}
              {defaultRates404 && (
                <Alert color="danger" className="mx-2">
                  We couldn't find rates for above candidate or client. Please
                  set up rates and try again later. Note: Please check with your
                  Admin if the "Default rates" for above {ClientLable}.
                </Alert>
              )}
              {selectedFile?.manuallyReviewed && (
                <Alert color="warning" className="mx-2">
                  This timesheet has already been reviewed. Please verify again
                  only if necessary.
                </Alert>
              )}

              {selectedFile?.preVerifiedAndProccessedStatus?.status ===
                "PRE_VERIFIED" && (
                <Alert color="danger" className="mx-2">
                  Already Processed: This timesheet has already been reviewed
                  and verified. Please check before re-processing.
                </Alert>
              )}

              <Button
                color={`${
                  selectedFile?.preVerifiedAndProccessedStatus?.status ===
                  "PRE_VERIFIED"
                    ? "danger"
                    : "success"
                } `}
                type="submit"
                disabled={loading}
              >
                {aiPending && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Verify
                {selectedFile?.preVerifiedAndProccessedStatus?.status ===
                  "PRE_VERIFIED" && (
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={faExclamationTriangle}
                  ></FontAwesomeIcon>
                )}
              </Button>
              <Button
                disabled={loading}
                color="danger"
                className=" mx-2"
                onClick={() => handleReject()}
              >
                Reject
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
      <AddRejectNoteModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSave={handleSaveRejectNote}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
    user: state.auth.item.firstName + " " + state.auth.item.lastName,
    timesheet: state.timesheets.timesheets,
    reduxGrades: state.initialDataGetReducer.grades,
  };
}

export default connect(mapStateToProps, {})(CandyTimesheetDataBatchViewAngi);
