import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Button,
  Col,
  UncontrolledTooltip,
  Input,
  Label,
  Badge,
  Row,
  CardBody,
  Card,
  Collapse,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../src/images/puff.svg";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import NoConnection from "../../pages/errorPages/NoConnection";
import { useSpring, animated } from "react-spring";
import { FunnelIcon } from "../../assets/icons/svg";
import { Link } from "react-router-dom";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import AddRejectNoteModal from "../../components/AddANote/AddRejectNote";
import { sendNotification } from "../../util/common/sendNotification";
import usePayroll from "../../customHooks/usePayroll";
import dateFormatter from "../../util/common/dateFormatter";
import DateInput from "../../util/common/DateInput";

const EmployeePayrollWidgetVerified = () => {
  const MexxarAPI = HOSTMexxar;
  const currentDate = new Date();

  const defaultStartDay = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).subtract(30, "days")
  );
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).add(1, "days")
  );

  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchAdvanced, setSearchAdvanced] = useState(false);

  const [resetResults, setResetResults] = useState(false);

  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [getStartDate, setGetStartDate] = useState(defaultStartDay);

  const { addToast } = useToasts();
  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [selectedRow, setSelectedRow] = useState(null);
  const handleReject = (row) => {
    setSelectedRow(row);
    toggleModal();
  };

  const handleSaveRejectNote = (noteReject) => {
    if (selectedRow) {
      RejectTimesheet(selectedRow, noteReject);
      toggleModal();
    }
  };

  let url = MexxarAPI + "workspace/payroll?rs=PENDING&candidateStatus=ACTIVE";

  const { loading, error, rows, hasMore, totalElements } = usePayroll(
    url,
    query,
    pageNumber,
    searchAdvanced,
    moment(getStartDate).format("YYYY/MM/DD"),
    moment(getEndDate).format("YYYY/MM/DD"),
    resetResults
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const reset = () => {
    setPageNumber(1);
    setResetResults(!resetResults);
    setGetStartDate(defaultStartDay);
    setGetEndDate(tommorow);
  };

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  const RejectTimesheet = (row, rejectReason) => {
    const encodedRejectReason = encodeURIComponent(rejectReason);

    axios
      .patch(
        `${HOSTMexxar}timesheets/rejectTimesheet/${row.id}?status=DECLINE&rejectReason=${encodedRejectReason}`
      )
      .then((res) => {
        if (res.data.status === "Success") {
          const { bookingRef, dateOfShift, startTime, endTime } =
            res.data.body[0];

          const formattedDate =
            moment(dateOfShift).format("dddd, MMMM D, YYYY");

          sendNotification({
            notificationType: "TIME_SHEET",
            title: `Timesheet for Booking ${bookingRef} on ${formattedDate} Rejected`,
            content: `Your timesheet has been rejected due to: ${rejectReason}. Scheduled time was from ${startTime} to ${endTime}.`,
            candidateId: row.booking.candidateId,
            startDate: new Date(),
            addToast: addToast,
          });
          ToastCommon(true, "success", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const handleSearchOnChange = (e) => {
    setPageNumber(1);
    setQuery(e.target.value);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements} {}Timesheets
        </Label>
        <Link to="/payroll/mobileTimesheetScan">
          <Button color="success" className=" mr-2">
            Batch Review
          </Button>
        </Link>
        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Payroll..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>

      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id/{ClientLable} Name
      </UncontrolledTooltip>

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>

                <DateInput
                  id="startDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
                  onChange={(date) =>
                    setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="endDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
                  onChange={(date) =>
                    setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setPageNumber(1);
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      <div style={{ overflowY: "auto", maxHeight: "90vh" }}>
        {Object.keys(rows).length === 0 && !loading && !error && (
          <NoSearchResults />
        )}

        {rows.map((row, index) => {
          if (rows.length === index + 1) {
            return (
              <div ref={lastElementRef} key={row.id}>
                <BookingCard
                  animationProps={animationProps}
                  key={row.id}
                  row={row}
                  ClientLabel="Client"
                  candidateAmendedStatus={row.candidateAmendedStatus}
                  toggleShowTimeModal={toggleShowTimeModal}
                  handleReject={handleReject}
                />
              </div>
            );
          } else {
            return (
              <div key={row.id}>
                <BookingCard
                  animationProps={animationProps}
                  key={row.id}
                  row={row}
                  ClientLabel="Client"
                  candidateAmendedStatus={row.candidateAmendedStatus}
                  toggleShowTimeModal={toggleShowTimeModal}
                  handleReject={handleReject}
                />
              </div>
            );
          }
        })}
      </div>

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheet
          data={data}
          closeModal={() => setShowTimeModal(false)}
        ></TimeSheet>
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>

      <AddRejectNoteModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSave={handleSaveRejectNote}
      />
    </div>
  );
};

export default EmployeePayrollWidgetVerified;

const BookingCard = ({
  animationProps,
  id,
  row,
  ClientLabel,
  candidateAmendedStatus,
  toggleShowTimeModal,
  handleReject,
}) => {
  return (
    <animated.div style={animationProps} key={id}>
      <div className="card mb-2" key={id}>
        <div className="card-body">
          <div className="flex flex-row d-flex justify-content-between">
            <div className="d-flex" style={{ width: "250px" }}>
              <div className="d-flex align-items-center mr-3">
                <span className="mx-2">
                  <b className="badge badge-circle sm text-primary"></b>
                </span>
              </div>
              <div>
                <div>{row.booking.candidateName}</div>
                <div className="item-mail text-muted h-1x d-none d-sm-block">
                  <time
                    dateTime={moment(row.booking.bookingDate).toISOString()}
                    title={moment(row.booking.bookingDate).format(
                      "MMMM D, YYYY at h:mm A"
                    )}
                    aria-label={moment(row.booking.bookingDate).format(
                      "MMMM D, YYYY h:mm A"
                    )}
                  >
                    <small>
                      Booking Date:{" "}
                      {moment(row.booking.bookingDate).format("llll")}
                    </small>
                  </time>
                </div>
              </div>
            </div>

            <div className="textCenter-Custom">
              <div>{ClientLabel} Name</div>
              <div className="text-muted">{row.hospitalName}</div>
            </div>

            <div className="textCenter-Custom">
              <div>Submitted Date</div>
              <div className="text-muted">
                {moment(row.submitedDate).format("llll")}
              </div>
            </div>

            <div className="textCenter-Custom">
              <div>Candidate Status:</div>
              <div>
                <Badge
                  color={
                    candidateAmendedStatus === "AMENDED" ? "success" : "danger"
                  }
                >
                  {candidateAmendedStatus}
                </Badge>
              </div>
            </div>

            <div>
              <Button
                color="success"
                className="mr-2"
                onClick={() => toggleShowTimeModal(row)}
              >
                Show
              </Button>
              <Button color="danger" onClick={() => handleReject(row)}>
                Reject
              </Button>
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  );
};
