import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
  Spinner,
} from "reactstrap";
import {
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import moment from "moment";
import axios from "axios";
import { forEach, uniqBy, filter } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClientLable,
  HOSTMexxar,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import useDidMountEffect from "./intialRunUseEffect";
import { connect } from "react-redux";

function TimeSheet(props) {
  const isDataEdited = true;
  const MexxarAPI = HOSTMexxar;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const { register, handleSubmit, errors } = useForm();
  const { data, closeModal, auth } = props;
  const { addToast } = useToasts();

  const [likeClients, setLikeClients] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [incomingData, setIncomingData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [candidateClients, setCandidateClients] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [candidateLikeWards, setCandidateLikeWards] = useState();
  const [modalPreviewPicture, setModalPreviewPicture] = useState();
  const [candidateDocumentUrl, setCandidateDocumentUrl] = useState();
  const [uploadedImageDocument, setUploadedImageDocument] = useState();
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [wardsInLikeClients, setWardsInLikeClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBand, setSelectedBand] = useState(0);
  const [candidateSpecialitiesCheck, setCandidateSpecialitiesCheck] = useState(
    []
  );
  const [locumId, setLocumId] = useState(props?.candidatesId);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSubmit = (data) => {
    let timesheet = {
      id: incomingData.id,
      locumName: incomingData.locumName,
      locumId: incomingData.locumId,
      speciality: incomingData.speciality,
      band: incomingData.band,
      hospitalName: selectedClient?.value,
      dateOfShift: incomingData.dateOfShift,
      ward:
        incomingData.ward == data.candidateLikeWardsSelect
          ? incomingData.ward
          : data.candidateLikeWardsSelect,
      bookingRef:
        incomingData.bookingRef == data.RefNo
          ? incomingData.bookingRef
          : data.RefNo,
      startTime:
        incomingData.startTime == data.StartTime
          ? incomingData.startTime
          : data.StartTime,
      endTime:
        incomingData.endTime == data.endTime
          ? incomingData.endTime
          : data.endTime,
      minsTakenForBreaks:
        incomingData.minsTakenForBreaks == data.breakTime
          ? incomingData.minsTakenForBreaks
          : data.breakTime,
      totalHours: incomingData.totalHours,
      locumDate: incomingData.locumDate,
      authoriseName: incomingData.authoriseName,
      authorisePosition: incomingData.authorisePosition,
      authoriseDate: incomingData.authoriseDate,
      documentUrl: incomingData.documentUrl,
      bookingId: incomingData.booking.id,
      hospitalReviewStatus: incomingData.hospitalReviewStatus,
      paymentStatus: incomingData.paymentStatus,
      reviewStatus: "VERIFIED",
    };

    axios.post(MexxarAPI + "timesheets/", timesheet).then((res) => {
      if (res.data.status == "success" || res.data.message == "created") {
        ToastCommon(true, "update", addToast);
      } else {
        ToastCommon(true, "duplicate", addToast);
      }

      if (selectedDocument != null || selectedDocument != undefined) {
        axios
          .put(
            MexxarAPI +
              "timesheets/" +
              incomingData.id +
              "/upload-timesheetDocument",
            selectedDocument
          )
          .then((res) => {
            if (res.data.status == "success" || res.data.message == "created") {
              ToastCommon(true, "update", addToast);
              closeModal();
            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      }
    });
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let form = new FormData();
      form.append("file", e.target.files[0]);
      setSelectedDocument(form);
    }
  };

  const GetSpecialties = () => {
    axios.get(MexxarCandidate + "/" + data.booking.candidatesId).then((res) => {
      let preferedClient = [];
      forEach(res.data.body[0].preferredClients, function (client) {
        preferedClient.push({
          id: client.id,
          name: client.name,
        });
      });

      setLikeClients(preferedClient);
    });
  };

  const getCandidate = (data) => {
    setLoading(true);
    axios
      .get(MexxarAPI + "candidates/" + data?.candidatesId)
      .then((candidate) => {
        let hospitals = candidate.data?.body[0]?.preferredClients;
        setSelectedBand(candidate.data?.body?.[0]?.candidateBand?.id);
        if (hospitals !== undefined) {
          let candidateHospitals = [];
          for (let i = 0; i < hospitals.length; i++) {
            candidateHospitals.push({
              value: hospitals[i].name,
              label: hospitals[i].name,
              id: hospitals[i].id,
              // candidate: candidate,
            });
          }

          setCandidateClients(candidateHospitals);

          let clientId = filter(candidateHospitals, (item) => {
            return item.id === data?.clientId;
          });
          console.log("angi clientID---->", clientId);
          setSelectedClient(clientId[0]);
        }

        let likeWards = candidate.data.body[0].likeWards;
        if (likeWards !== undefined) {
          let candidateLikeWards = [];
          for (let i = 0; i < likeWards.length; i++) {
            if (likeWards[i] !== undefined) {
              candidateLikeWards.push({
                value: likeWards[i].wardName,
                label: likeWards[i].wardName,
                id: likeWards[i].id,
                candidate: candidate,
              });
            }
          }
          setCandidateLikeWards(candidateLikeWards);
        }

        let candidateSpecialities =
          candidate.data.body[0].candidateSpecialities;
        if (candidateSpecialities !== undefined) {
          let candidateSpecialitiesCheck = [];
          for (let i = 0; i < candidateSpecialities.length; i++) {
            candidateSpecialitiesCheck.push({
              value: candidateSpecialities[i].name,
              label: candidateSpecialities[i].name,
              id: candidateSpecialities[i].id,
            });
          }
          setCandidateSpecialitiesCheck(candidateSpecialitiesCheck);
        }

        let id = candidate.data.body[0].id;
        if (id !== undefined) {
          setLocumId(id);
          let candidateIdCheck = [];
          for (let i = 0; i < id.length; i++) {
            candidateIdCheck.push({
              value: id,
              label: id,
              id: id,
              candidate: candidate,
            });
          }
        }

        setCandidateDocumentUrl(candidate.data.body[0].documentUrl);
        setLoading(false);
      });
  };

  const downloadUploadedDocument = () => {
    axios({
      url:
        MexxarAPI +
        "timesheet-doc/candidates/" +
        incomingData.booking.candidatesId +
        "/" +
        uploadedImageDocument,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("target", "_blank");
      link.setAttribute("download", uploadedImageDocument);
      document.body.appendChild(link);
      link.click();
      // window.open(link);
    });
  };

  const loadModalPreviewPicture = () => {
    if (incomingData != undefined) {
      let url = String(
        MexxarAPI +
          "timesheet-doc/candidates/" +
          incomingData.booking.candidatesId +
          "/" +
          uploadedImageDocument
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
          setOpenModal(true);
          // const link = document.createElement('a');
          // link.href = result;
          // link.setAttribute("target", "_blank");
          // // link.setAttribute('download', uploadedImageDocument);
          // document.body.appendChild(link);
          // link.click();
          // window.open(result);
        });
      });
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  const getClientWards = () => {
    let ClientsWard = [];
    axios
      .get(HOSTMexxar + "client-wards/clients/" + selectedClient?.id)
      .then((res) => {
        if (res.data.status == "success") {
          let response = res.data.body;
          forEach(response, function (item) {
            ClientsWard.push({
              value: item.wardName,
              label: item.wardName,
              id: item.id,
            });
          });
          setWardsInLikeClients(uniqBy(ClientsWard, "id"));

          let wardId = filter(response, (item) => {
            return item?.id === Number(data?.ward);
          });

          setSelectedDepartment(wardId[0]?.id);
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        console.log("err clients wardss--->", err);
        ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    getClientWards();
  }, [selectedClient]);

  useEffect(() => {
    setIncomingData(data);
    data.documentUrl == null
      ? setUploadedImageDocument(null)
      : setUploadedImageDocument(data.documentUrl.split("/")[1]);
    GetSpecialties();
    getCandidate(data.booking);
    loadModalPreviewPicture();
  }, []);

  return (
    <>
      {" "}
      {loading ? (
        <>
          Loading ...
          <Spinner
            className="mx-2  "
            style={{
              height: "20px",
              width: "20px",
              marginRight: "5px",
            }}
            loading={loading}
          />
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Label sm={2} md={2}>
              Ref
            </Label>
            <Col sm={9} md={9}>
              <Input
                type="text"
                placeholder="Ref No"
                name="RefNo"
                id="RefNoId"
                defaultValue={
                  incomingData == undefined || incomingData == null
                    ? ""
                    : String(incomingData.bookingRef)
                }
                innerRef={register({ required: true })}
              />
            </Col>
            <Col sm={1} md={1}>
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>{ClientLable}</Label>
            <Col sm={9}>
              <Select
                defaultValue={selectedClient}
                // isMulti
                isDisabled={true}
                name="clientId"
                className="basic-multi-select  "
                classNamePrefix="select"
                onChange={(data) => setSelectedClient(data)}
                options={candidateClients}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} md={2}>
              Department
            </Label>
            <Col sm={9} md={9}>
              <Input
                type="select"
                name="candidateLikeWardsSelect"
                value={selectedDepartment}
                onChange={(data) => setSelectedDepartment(data.target.value)}
                innerRef={register({ required: true })}
              >
                <>
                  {wardsInLikeClients.map((item, id) => {
                    return (
                      <option value={item.id} key={id}>
                        {item.value}
                      </option>
                    );
                  })}
                </>
              </Input>
            </Col>
            <Col sm={1} md={1}>
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
          </FormGroup>{" "}
          <FormGroup row>
            <Label sm={2} md={2}>
              Date Of Shift
            </Label>
            <Col sm={9} md={9}>
              <Input
                type="date"
                readOnly
                name="dateOfShift"
                innerRef={register({ required: true })}
                defaultValue={moment(incomingData?.dateOfShift).format(
                  "YYYY-MM-DD"
                )}
              ></Input>
            </Col>
            <Col sm={1} md={1}>
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} md={2}>
              From <br />
              (24 HR Format)
            </Label>
            <Col sm={3}>
              <Input
                type="time"
                placeholder="Start Time"
                name="StartTime"
                innerRef={register({ required: true })}
                defaultValue={
                  incomingData == undefined || incomingData == null
                    ? ""
                    : moment(incomingData.booking.shiftFrom).format("HH:mm")
                }
              />
            </Col>
            <Col sm={1} md={1} className="d-flex align-items-center">
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
            <Label sm={1} md={1}>
              To
            </Label>
            <Col sm={4}>
              <Input
                type="time"
                placeholder="Start Time"
                name="endTime"
                defaultValue={
                  incomingData == undefined || incomingData == null
                    ? ""
                    : moment(incomingData.booking.shiftTo).format("HH:mm")
                }
                innerRef={register({ required: true })}
              />
            </Col>
            <Col sm={1} md={1} className="d-flex align-items-center">
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Break Mins</Label>
            <Col sm={9}>
              <Input
                type="text"
                placeholder="Break Time"
                name="breakTime"
                innerRef={register({ required: true })}
                defaultValue={incomingData?.minsTakenForBreaks}
              />
            </Col>
            <Col sm={1} md={1} className="d-flex align-items-center">
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
          </FormGroup>{" "}
          <FormGroup row>
            <Label sm={2}>Total Hours</Label>
            <Col sm={9}>
              <Input
                type="text"
                placeholder="Total Hours"
                name="totalHours"
                defaultValue={incomingData?.totalHours}
                innerRef={register()}
              />
            </Col>
            <Col sm={1} md={1} className="d-flex align-items-center">
              {isDataEdited ? (
                <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
              ) : null}
            </Col>
          </FormGroup>
          {/* <FormGroup row>
       <Label sm={3}>Time Sheet</Label>
       <Col sm={8}>
       <h5><Badge href="#" color="primary">Manual</Badge>{'  '}</h5> */}
          {/* <Badge href="#" color="primary">Review</Badge></h5> */}
          {/* </Col>
        </FormGroup> */}
          <FormGroup row>
            <Label sm={4}>Image</Label>
            <Col sm={8}>
              {/* <img src="https://mdbootstrap.com/img/Others/documentation/img%20(75)-mini.jpg" alt="thumbnail" className="img-thumbnail" /> */}

              {/* <Label sm={4}>{uploadedImageDocument}</Label> */}
              {uploadedImageDocument == null ||
              uploadedImageDocument == undefined ? (
                <>
                  <Input
                    type="file"
                    id="fileId"
                    onChange={onChangePicture}
                    name="file"
                    innerRef={register({ required: "File is required" })}
                  />
                  {errors.file && (
                    <span className="text-danger">{errors.file.message}</span>
                  )}
                </>
              ) : (
                <>
                  <Button onClick={loadModalPreviewPicture}>
                    View Uploaded File
                  </Button>

                  <Label sm={12} for="file" className="pl-0">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-2"
                    ></FontAwesomeIcon>
                    Replace Timesheet
                  </Label>
                  <Input
                    type="file"
                    id="fileId"
                    onChange={onChangePicture}
                    name="file"
                  />
                </>
              )}
            </Col>
            <Col sm={8}>{/* <img src={modalPreviewPicture} sm={4}/> */}</Col>
          </FormGroup>
          <div className="  d-flex justify-content-end">
            {auth?.item?.userType === "EMPLOYEE" ? (
              <Button
                color="success"
                className="mx-2"
                type="submit"
                onClick={() => closeModal()}
              >
                Verify
              </Button>
            ) : null}

            <Button
              color="secondary"
              className="mr-3"
              onClick={() => closeModal()}
            >
              Close
            </Button>
          </div>
        </Form>
      )}
      <Modal
        isOpen={openModal}
        scrollable={false}
        className="modal-dialog modal-lg"
      >
        <Form>
          {"Text" && (
            <ModalHeader className="modal-header bg-primary">
              <span style={{ color: "#ffffff" }}>{"Document Preview"}</span>
            </ModalHeader>
          )}
          <ModalBody className="p-4 ">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <label for="file-input">
                  <img
                    src={modalPreviewPicture}
                    width={"700px"}
                    height={"700px"}
                  ></img>
                </label>
              </div>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(TimeSheet);
