import React, { useState, useEffect } from "react";
import { Alert, Button, Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Row } from "reactstrap";
import axios from "axios";
import ToastCommon from "../../toastCommon";
import { CompanyName, HOSTMexxar } from "../../../configs/api-config";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../../pages/errorPages/NoSearchResults";

export default function CandidatesChecksHistory({
  consultantLastName,
  consultantFirstName,
  candidateData,
  type,
  onClose,
  resetCandidate,

}) {
  const { addToast } = useToasts();
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    bool: false,
  });
  const [checkIsProcessing, setCheckIsProcessing] = useState(false);
  const [nmcHistoryData, setNMCHistoryData] = useState(null);
  const [hcpcHistoryData, setHCPCHistoryData] = useState(null);
  const [dbsHistoryData, setDBSHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);

  const DBSHistoryCheck = async () => {
    if (!candidateData?.id) {
      console.error("Candidate ID is missing.");
      return;
    }

    
    setErrorMessage({ message: "", bool: false });
    setLoading(true);

    try {
      const res = await axios.get(`${HOSTMexxar}dbshistory/findAll`, {
        params: { candidateId: candidateData.id },
      });
    

      setLoading(false);

      if (res.data.status === "Success") {
        resetCandidate();
        const dbsHistory = res.data.body.content;
        setDBSHistoryData(dbsHistory);
        
 
      } else {
        setErrorMessage({ message: res.data.status, bool: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      ToastCommon(true, "error", addToast);
    }
  };

  const HCPCHistoryCheck = async () => {
    if (!candidateData?.id) {
      console.error("Candidate ID is missing.");
      return;
    }

    
    setErrorMessage({ message: "", bool: false });
    setLoading(true);

    try {
      const res = await axios.get(`${HOSTMexxar}hcpchistory/findAll`, {
        params: { candidateId: candidateData.id },
      });
    

      setLoading(false);

      if (res.data.status === "Success") {
        resetCandidate();
        const hcpcHistory = res.data.body.content;
        setHCPCHistoryData(hcpcHistory);
     
      } else {
        setErrorMessage({ message: res.data.status, bool: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      ToastCommon(true, "error", addToast);
    }
  };

  const NMCHistoryCheck = async () => {
    if (!candidateData?.id) {
      console.error("Candidate ID is missing.");
      return;
    }

   
    setErrorMessage({ message: "", bool: false });
    setLoading(true);

    try {
      const res = await axios.get(`${HOSTMexxar}nmchistory/findAll`, {
        params: { candidateId: candidateData.id },
      });
      console.log("API response:", res.data);

      setLoading(false);

      if (res.data.status === "Success") {
        resetCandidate();
        const nmcHistory = res.data.body.content;
        setNMCHistoryData(nmcHistory);

      } else {
        setErrorMessage({ message: res.data.status, bool: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      ToastCommon(true, "error", addToast);
    }
  };

  useEffect(() => {
    switch (type) {
      case "NMC":
        NMCHistoryCheck();
      
        break;
      case "DBS":
        DBSHistoryCheck();
        
        break;
      case "HCPC":
        HCPCHistoryCheck();
       
        break;
      default:
        console.error("Unknown check type");
    }
  }, [type]);
  const renderCardContent = () => {
    const formatData = (data) => (data === null || data === undefined ? "N/A" : data);
    
    const noDataFoundMessage = (
  
      <NoSearchResults/>

    );
  
    switch (type) {
      case "NMC":
        return nmcHistoryData && nmcHistoryData.length > 0 ? (
          nmcHistoryData.map((data, index) => (
            <Alert key={index} className="mb-3">
              <CardBody>
                <hr />
                <CardText>
                  <Row>
                    <Col md={6}>{type} Check Date</Col>
                    <Col md={6}>{formatData(moment(data.nmcCheckDate).format("YYYY-MM-DD"))}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>NMC Pin</Col>
                    <Col md={6}>{formatData(data.nmcPin)}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>Created Date</Col>
                    <Col md={6}>{formatData(moment(data.createdDate).format("YYYY-MM-DD"))}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>NMC Send Status</Col>
                    <Col md={6}>{formatData(data.nmcSendStatus)}</Col>
                  </Row>
                  <br />
             
                </CardText>
              </CardBody>
            </Alert>
          ))
        ) : (
          noDataFoundMessage
        );
      case "DBS":
        return dbsHistoryData && dbsHistoryData.length > 0 ? (
          dbsHistoryData.map((data, index) => (
            <Alert key={index} className="mb-3">
              <CardBody>
                <hr />
                <CardText>
                  <Row>
                    <Col md={6}>Created Date</Col>
                    <Col md={6}>{formatData(moment(data.createdDate).format("YYYY-MM-DD"))}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}> {type} Check Date</Col>
                    <Col md={6}>{formatData(moment(data.dbsCheckDate).format("YYYY-MM-DD"))}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>{type} Check Status</Col>
                    <Col md={6}>{formatData(data.dbsCheckStatus)}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>{type} Reference</Col>
                    <Col md={6}>{formatData(data.dbsReference)}</Col>
                  </Row>
                </CardText>
              </CardBody>
            </Alert>
          ))
        ) : (
          noDataFoundMessage
        );
      case "HCPC":
        return hcpcHistoryData && hcpcHistoryData.length > 0 ? (
          hcpcHistoryData.map((data, index) => (
            <Alert key={index} className="mb-3">
              <CardBody>
                <hr />
                <CardText>
                  <Row>
                    <Col md={6}>{type} Check Date</Col>
                    <Col md={6}>{formatData(moment(data.hcpcCheckDate).format("YYYY-MM-DD"))}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>Created Date</Col>
                    <Col md={6}>{formatData(moment(data.createdDate).format("YYYY-MM-DD"))}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>HCPC Registration Number</Col>
                    <Col md={6}>{formatData(data.hcpcRegistrationNumber)}</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>HCPC Check Status</Col>
                    <Col md={6}>{formatData(data.hcpcCheckStatus)}</Col>
                  </Row>
                  <Row>
                    <Col md={6}>Hcpc Prof Code</Col>
                    <Col md={6}>{formatData(data.hcpcProfCode)}</Col>
                  </Row>
                </CardText>
              </CardBody>
            </Alert>
          ))
        ) : (
          noDataFoundMessage
        );
      default:
        return null;
    }
  };
  

  return (
    <div>
      <Form>
      <FormGroup className="mb-0">
      {loading ? (
            <div className="text-center">
              <img src={loadingAnimation} alt="Loading..." style={{ height: 100 }} />
            </div>
          ) : (
            <>
              {type && renderCardContent()}
              <Row>
                <Col md={12} className="d-flex align-items-center justify-content-end">
                  <Button onClick={onClose}>Close</Button>
                </Col>
              </Row>
            </>
          )}
        </FormGroup>
      </Form>
    </div>
  );
}
